import { Col, Container, Image, Row } from 'react-bootstrap';
import SwipperSection from './SwipperSection';
import SwipperSlider from '../../basic/SwipperSlider';
import { DynamicCarousel } from './DynamicCarousel';
import { FaInstagram, FaWhatsapp } from 'react-icons/fa';

import GoogleMap from '../../../Litho/Components/GoogleMap/GoogleMap';
import SwipperSliderContain from '../../basic/SwipperSliderContain';

const LandingSection = () => {
  const imagesData = [
    {
      img: '/assets/img/home-page/home-3-slide-1.jpg',
    },
    {
      img: '/assets/img/home-page/home-3-slide-2.jpg',
    },
    {
      img: '/assets/img/home-page/home-3-slide-3.jpg',
    },
    {
      img: '/assets/img/home-page/home-3-slide-4.jpg',
    },
    {
      img: '/assets/img/home-page/home-3-slide-5.jpg',
    },
    {
      img: '/assets/img/home-page/home-3-slide-6.jpg',
    },
  ];
  const swiperData = [
    {
      img: '/assets/img/home-page/home-4-slide-1.jpg',
    },
    {
      img: '/assets/img/home-page/home-4-slide-2.jpg',
    },
    {
      img: '/assets/img/home-page/home-4-slide-3.jpg',
    },
    {
      img: '/assets/img/home-page/home-4-slide-4.jpg',
    },
    {
      img: '/assets/img/home-page/home-4-slide-5.jpg',
    },
    {
      img: '/assets/img/home-page/home-4-slide-6.jpg',
    },
    {
      img: '/assets/img/home-page/home-4-slide-7.jpg',
    },
    {
      img: '/assets/img/home-page/home-4-slide-8.jpg',
    },
    {
      img: '/assets/img/home-page/home-4-slide-9.jpg',
    },
    {
      img: '/assets/img/home-page/home-4-slide-10.jpg',
    },
    {
      img: '/assets/img/home-page/home-4-slide-11.jpg',
    },
  ];
  const homePageData = [
    {
      img: '/assets/img/home-page/home-5-slide-1.jpg',
    },
    {
      img: '/assets/img/home-page/home-5-slide-2.jpg',
    },
    {
      img: '/assets/img/home-page/home-5-slide-3.jpg',
    },
    {
      img: '/assets/img/home-page/home-5-slide-4.jpg',
    },
    {
      img: '/assets/img/home-page/home-5-slide-5.jpg',
    },
    {
      img: '/assets/img/home-page/home-5-slide-6.jpg',
    },
    {
      img: '/assets/img/home-page/home-5-slide-7.jpg',
    },
    {
      img: '/assets/img/home-page/home-5-slide-8.jpg',
    },
    {
      img: '/assets/img/home-page/home-5-slide-9.jpg',
    },
  ];
  return (
    <div className='overflow-hidden relative'>
      <SwipperSection />

      <Container className='relative space-y-12 mt-12'>
        <div className='text-[20px] leading-9 text-[#c5c5c5] sm:text-[16px]'>
          <b className='text-white text-[24px] sm:text-[20px]'>
            Experience the True Essence of Sak Yant
          </b>
          <br />
          An Ancient Ritual Filled with Power and Spiritual Energy
          <br />
          <br />
          Here, we are not just a tattoo studio—we are a sacred place that
          preserves the traditional art of Sak Yant. Every step follows ancient
          rituals passed down through generations. With over 200 years of
          history, we are the 4th-generation heirs of this sacred practice,
          guiding you through the profound journey of traditional Sak Yant from
          the very first moment to the final blessing.
          <br />
          <br />
          <b className='text-white text-[24px] sm:text-[20px]'>
            Hand-Poked Sak Yant – The True Art of Power
          </b>
          <br />
          <br />
          We uphold the ancient hand-poked method of Sak Yant—no modern tattoo
          machines, no shortcuts. Every design is inscribed manually, using a
          sacred needle guided by hand, allowing the power of the Yant to
          connect deeply with your spirit. Sacred chants are recited throughout
          the process, awakening the energy of your tattoo as it is permanently
          etched onto your skin.
        </div>
        <div className='d-flex justify-center '>
          <div className='w-50'>
            <Image
              src='/assets/img/home-page/homepage-2.jpg'
              alt='gold'
              className='object-cover w-full h-full flex'
            />
          </div>
        </div>
        <div className='text-[20px] leading-9 text-[#c5c5c5] sm:text-[16px]'>
          <b className='text-white text-[24px] sm:text-[20px]'>
            A Sacred Ceremony – More Than Just a Tattoo
          </b>
          <br />
          <br />
          A Sak Yant tattoo is not just ink on your body—it is a spiritual
          ritual that must be performed with deep respect and intention.
          <br />
          <ul className='p-6 !list-disc'>
            <li>
              Before the Tattoo – You will take part in a sacred ritual to
              connect with the power of your chosen Yant. We begin with a
              ceremony to honor the ancestral masters and seek their blessing.
            </li>
            <li>
              During the Tattoo – Every needle stroke is guided by an
              experienced master, accompanied by ancient chants that activate
              the power of the Yant.
            </li>
            <li>
              After the Tattoo – A final ritual is performed to strengthen the
              energy of your Sak Yant and align it with your destiny.
            </li>
          </ul>
        </div>
        {/* <Carousel slides={images} /> */}

        <SwipperSliderContain
          swiperData={imagesData}
          largeHeight={'550px'}
          mobileHeight={'350px'}
        />
        {/* <SwipperSlider /> */}
        {/* <Example /> */}
        {/* <ImageCarousel /> */}

        <div className='text-[20px] leading-9 text-[#c5c5c5] sm:text-[16px]'>
          <b className='text-white text-[24px] sm:text-[20px]'>
            Feel the Energy – A Spiritual Experience Beyond the Tattoo
          </b>
          <br />
          <br />
          The echo of sacred chants as the needle touches your skin…
          <br />
          The sensation of ancient power awakening within you…
          <br />
          The moment you first see your completed Yant, knowing it carries deep
          spiritual meaning…
          <br />
          <br />
          This is an experience like no other—one that will stay with you for a
          lifetime.
          <br />
          <br />
          If you seek a traditional Sak Yant that is more than just an artistic
          design—one filled with power, faith, and spiritual connection— we
          welcome you to step into the sacred world of true, ancient Sak Yant.
        </div>
        <SwipperSlider
          swiperData={swiperData}
          imageType={'cover'}
          mobileHeight={'350px'}
        />
        <div className='text-[20px] leading-9 text-[#c5c5c5] sm:text-[16px]'>
          <b className='text-white text-[24px] sm:text-[20px]'>
            What Makes Us Different – Important Things You Should Know
          </b>
          <br />
          <br />
          Sak Yant is an ancient art passed down through generations. It is not
          just about tattooing patterns onto the skin but a sacred practice
          deeply connected to energy, faith, and the spirit of the wearer.
          Choosing the right place and the right Master to perform your Sak Yant
          is crucial.
          <br />
          <br />
          Here at Sak Yant Bangkok, we are a traditional Sak Yant center with a
          history of over 200 years. Our Master is a 4th-generation heir of this
          sacred practice, trained in the true ancient methods. We are not a
          foreign-owned shop hiring a local Master to serve customers, nor are
          we an agency website that takes tourists to various locations for Sak
          Yant while adding extra charges.
          <br />
          <br />
          <b className='text-white text-[24px] sm:text-[20px]'>
            What Makes Us Different?
          </b>
          <br />
          <ol className='p-6 !list-decimal'>
            <li>
              <b>Sak Yant by a True Master with Real Expertise</b>
              <ul className='p-2 !list-disc'>
                <li>
                  Our Master has inherited this sacred knowledge from his
                  ancestors. He is not just a tattoo artist but a true
                  practitioner of Sak Yant.
                </li>
                <li>
                  Every design carries deep spiritual meaning and is carefully
                  selected to match each individual—not just for aesthetics but
                  for the energy and power it provides.
                </li>
              </ul>
            </li>
            <li>
              <b>We Are Not a Commercial Tattoo Shop or a Tourist Agency</b>
              <br />
              We do not operate as a travel agency that brings foreigners for a
              Sak Yant experience while inflating prices.
              <ul className='p-2 !list-disc'>
                <li>
                  Everyone who comes here does so because they truly seek an
                  authentic Sak Yant, understanding its meaning and
                  significance—not because they were led here by a tour company.
                </li>
              </ul>
            </li>
            <li>
              <b>Traditional Hand-Poked Sak Yant Method</b>
              <ul className='p-2 !list-disc'>
                <li>
                  No tattoo machines are used here. We do not run a commercial
                  “tattoo shop” where modern machines are used for convenience.
                </li>
                <li>
                  Every Sak Yant is hand-poked (Hand-Poked Sak Yant) using the
                  traditional method, accompanied by sacred chanting and rituals
                  that preserve its true spiritual essence—without any
                  modifications or shortcuts.
                </li>
              </ul>
            </li>
          </ol>
        </div>
        <div className='text-[20px] leading-9 mt-0 text-[#c5c5c5] sm:text-[16px]'>
          <b className='text-white text-[24px] sm:text-[20px]'>
            Why Choose Us?
          </b>
          <br />
          <br />
          If you seek a true, authentic Sak Yant experience—not just a tattoo
          but something deeply meaningful and powerful—we invite you to see for
          yourself.
          <br />
          <ul className='p-6 !list-disc'>
            <li>
              No hidden costs. No inflated prices. Everything is transparent and
              clear to ensure you receive the best.
            </li>
            <li>
              We are not a mass tourist attraction. This place is open only to
              those who are genuinely dedicated to receiving the spiritual power
              of Sak Yant in its purest form.
            </li>
          </ul>
          If you are looking for an authentic Sak Yant place, performed by a
          true Master from a direct lineage, then Sak Yant Bangkok is the right
          place for you
        </div>
        <SwipperSliderContain
          swiperData={homePageData}
          imageType={'contain'}
          mobileHeight={'450px'}
          largeHeight={'550px'}
        />
        <div className='mt-24 flex justify-between sxl:flex-col sxl:justify-center sxl:items-center sxl:gap-12'>
          <div className='w-[50%] sxl:w-[100%]'>
            <div className='text-white font-bold below-bottom'>
              <h6 className='!mb-0'>Contact us on WhatsApp</h6>
            </div>
            <div className='mt-12 mb-12'>
              <a
                href='https://wa.me/+66657406999'
                target='_blank'
                rel='noopener noreferrer'
              >
                <button className='bg-[#f5f5f5] text-black  py-3 px-4 rounded-full flex items-center gap-3 notMobile:text-[24px] text-[18px]'>
                  <FaWhatsapp
                    size={32}
                    className=' hidden notMobile:block text-green-500'
                  />
                  <FaWhatsapp
                    size={24}
                    className='block notMobile:hidden text-green-500'
                  />
                  Sakyant Master Tor
                </button>
              </a>
            </div>
          </div>
          <div className='w-[50%] sxl:w-[100%]'>
            <div className='text-white font-bold below-bottom'>
              <h6 className='!mb-0'>Contact us on Instagram</h6>
            </div>
            <div className='mt-12 mb-12'>
              <a
                href='https://www.instagram.com/sakyant_bangkok?igsh=ZnNqd2gxb2JvZmY5&utm_source=qr'
                target='_blank'
                rel='noopener noreferrer'
              >
                <button className='bg-[#f5f5f5] text-black  py-3 px-4 rounded-full flex items-center gap-3  notMobile:text-[24px] text-[18px]'>
                  <FaInstagram
                    size={32}
                    className='hidden notMobile:block text-pink-500'
                  />
                  <FaInstagram
                    size={24}
                    className='block notMobile:hidden text-pink-500 '
                  />
                  sakyant_bangkok
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className='mt-20'>
          <GoogleMap
            className='h-[500px] xs:h-[300px]'
            location='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3873.1383931700293!2d100.68408679999999!3d13.890671999999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311d7ddbef6f247b%3A0xc48d768f67675329!2sSakyant%20Bangkok%20Real%20sakyant%20Traditional%20master%20to!5e0!3m2!1sen!2s!4v1742114087802!5m2!1sen!2s'
          />
        </div>
      </Container>
    </div>
  );
};

export default LandingSection;
