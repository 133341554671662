import React, { useState } from 'react';

// Libraries
import { Container, Image } from 'react-bootstrap';

const MainContainer = (props) => {
  return (
    <div>
      <Container className='mt-20'>
        <div className='text-[22px] leading-9 text-[#c5c5c5]'>
          <div className='text-white font-bold below-bottom'>
            <h5 className='!mb-0 mt-28 aboveMedium:mt-0'>
              Booking & Appointments
            </h5>
          </div>
        </div>

        <div className='flex flex-col mt-12 text-[20px] text-[#c5c5c5]'>
          Booking a Sak Yant tattoo with us is simple! You can make an
          appointment in advance through our website by following these steps:
          <ol className='p-6 !list-decimal'>
            <li>
              Fill in the appointment details – Choose the date and time that
              work best for you.
            </li>
            <li>
              Deposit payment – To confirm your booking, a small deposit is
              required (the amount will be specified during the booking
              process).
            </li>
            <li>
              Appointment confirmation – Once your deposit has been received, we
              will confirm your appointment as soon as possible.
            </li>
          </ol>
          Advance Booking Details:
          <ul className='p-6 !list-disc'>
            <li>
              We recommend booking at least 1–2 weeks in advance to ensure you
              get your preferred time slot.
            </li>
            <li>
              If you need to reschedule, please inform us at least 3 days in
              advance.
            </li>
            <li>
              In cases of cancellations or rescheduling, we reserve the right to
              withhold the deposit in certain cases.
            </li>
          </ul>
          Pricing:
          <ul className='p-6 !list-disc'>
            <li>
              Base price – Prices vary depending on the design and nature of the
              Sak Yant.
            </li>
            <li>
              Payment – A deposit of 2,000 THB per person is required, with the
              remaining balance to be paid on the appointment day.
            </li>
            <li>
              Why we do not offer discounts – Sak Yant is not just about the
              design; it is deeply connected to time, rituals, and spiritual
              practice. Our pricing is not based on size but on the sacred
              ceremonies performed from the beginning of the tattooing process
              to the final blessing. Each individual receives a unique and
              personal blessing, which is the most important part of the Sak
              Yant experience.
            </li>
          </ul>
          <strong className='text-white'>Why Choose Us?</strong>
          <ol className='p-6 !list-decimal'>
            <li>
              Expertise – We have extensive experience in the sacred art of Sak
              Yant, passed down through generations of masters. Our lineage has
              been preserved for over 200 years, and our master is the
              4th-generation heir of this tradition.
            </li>
            <li>
              Personalized Rituals – Every Sak Yant comes with a unique ritual
              to enhance its spiritual power, calling upon sacred masters and
              guardian deities to bless and protect your tattoo.
            </li>
            <li>
              Protection & Blessings – Each tattoo is performed according to
              ancient traditions, ensuring spiritual protection and prosperity
              in your life, without any modern modifications to the sacred
              practice.
            </li>
            <li>
              Protection & Blessings – Each tattoo is performed according to
              ancient traditions, ensuring spiritual protection and prosperity
              in your life, without any modern modifications to the sacred
              practice.
            </li>
            <li>
              Hygiene & Safety – We prioritize cleanliness and use high-quality
              materials for your safety. Our needles are single-use and disposed
              of after each session. Tattoo ink, Vaseline, and sacred oils are
              used only for each individual client—never shared or reused
            </li>
          </ol>
        </div>
      </Container>
    </div>
  );
};

export default MainContainer;
