import { useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import { TextArea } from '../../../Litho/Components/Form/Form';
import { TbSquareRoundedChevronDownFilled } from 'react-icons/tb';
import './booking.css';
import { Bounce, toast } from 'react-toastify';
import emailjs from '@emailjs/browser'; // Import EmailJS

const BookingForm = () => {
  const form = useRef(null);
  const [sendType, setSendType] = useState(null); // Track selected send type

  const sendEmail = (values) => {
    emailjs
      .send('service_v6iwdqs', 'template_4aqer8l', values, '6kFlN0Eqfxlr_bHVE')
      .then(
        (result) => {
          console.log(result);
          toast.success('Request Submitted Successfully', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
            transition: Bounce,
          });
        },
        (error) => {
          console.log(error.text);
          toast.error('Failed to submit request. Please try again.', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
            transition: Bounce,
          });
        }
      );
  };
  const sendViaWhatsApp = (values) => {
    const message = `Hello, I would like to book an appointment.
    - Name: ${values.name}
    - Email: ${values.email}
    - Phone: ${values.phone}
    - Tattoo Location: ${values.tattooLocation}
    - Size: ${values.size}
    - Gender: ${values.gender}
    - Color: ${values.tattooColor}
    - Date/Time: ${values.dateTime}
    - Cover-up: ${values.coverup}
    - Comments: ${values.comment}`;

    const whatsappURL = `https://wa.me/66657406999?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappURL, '_blank');
  };
  const sendViaEmail = (values) => {
    const subject = 'Appointment Booking';
    const body = `Hello,
    
I would like to book an appointment.
- Name: ${values.name}
- Email: ${values.email}
- Phone: ${values.phone}
- Tattoo Location: ${values.tattooLocation}
- Size: ${values.size}
- Gender: ${values.gender}
- Color: ${values.tattooColor}
- Date/Time: ${values.dateTime}
- Cover-up: ${values.coverup}
- Comments: ${values.comment}`;

    const mailtoLink = `mailto:Toanucha111@gmail.com?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  return (
    <Container className='flex flex-col gap-6 pt-[50px] pb-16'>
      <h5 className='font-serif text-tprimary font-semibold below-bottom'>
        BOOK AN APPOINTMENT
      </h5>
      <Formik
        initialValues={{
          name: '',
          email: '',
          phone: '',
          tattooLocation: '',
          size: '',
          gender: '',
          tattooColor: '',
          dateTime: '',
          coverup: '',
          comment: '',
        }}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true);
          if (sendType === 'email') {
            sendViaEmail(values);
          } else if (sendType === 'whatsapp') {
            sendViaWhatsApp(values);
          }
          sendViaEmail(values);
          // sendViaWhatsApp(values);
          actions.resetForm();
        }}
      >
        {({ isSubmitting, status, setFieldValue, resetForm }) => (
          <Form
            ref={form}
            className='flex flex-wrap w-full notMobile:grid gap-x-16 gap-y-6 sm:grid-cols-1 sm:gap-x-0 sm:gap-y-4 notMobile:grid-cols-2 notMobile:gap-x-16 notMobile:gap-y-6'
          >
            <Field
              type='text'
              name='name'
              className=' col-span-2 pt-[20px] text-[#8e98a6] h-[60px] pr-[36px] sm:w-full bg-transparent border-b border-solid border-tprimary text-[16px]'
              placeholder='Name *'
            />

            <Field
              type='email'
              name='email'
              className='pt-[20px] text-[#8e98a6] h-[60px] pr-[36px] sm:w-full bg-transparent border-b border-solid border-tprimary text-[16px]'
              placeholder='Email *'
            />

            <div className='relative sm:w-full'>
              <Field
                as='select'
                className='pt-[20px] w-full bg-tsecondary h-[60px] border-b border-solid border-tprimary text-[16px] text-[#8e98a6] appearance-none cursor-pointer'
                name='tattooLocation'
              >
                <option
                  className='text-tprimary hover:text-red-700'
                  value='default'
                  disabled
                  hidden
                >
                  Where do you want your tattoo?
                </option>
                <option className='text-tprimary' value='calf'>
                  Calf
                </option>
                <option className='text-tprimary' value='chest'>
                  Chest
                </option>
                <option className='text-tprimary' value='foot'>
                  Foot
                </option>
                <option className='text-tprimary' value='forearm'>
                  Forearm
                </option>
                <option className='text-tprimary' value='full-back'>
                  Full Back
                </option>
                <option className='text-tprimary' value='full-sleeve'>
                  Full Sleeve
                </option>
                <option className='text-tprimary' value='hlf-sleeve'>
                  Half Sleeve
                </option>
                <option className='text-tprimary' value='leg'>
                  Leg
                </option>
                <option className='text-tprimary' value='lower-back'>
                  Lower Back
                </option>
                <option className='text-tprimary' value='ribs'>
                  Ribs
                </option>
                <option className='text-tprimary' value='stomach'>
                  Stomach
                </option>
                <option className='text-tprimary' value='upper-arm'>
                  Upper Arm
                </option>
                <option className='text-tprimary' value='upper-back'>
                  Upper Back
                </option>
                <option className='text-tprimary' value='lower-arm'>
                  Lower Arm
                </option>
                <option className='text-tprimary' value='hip'>
                  Hip
                </option>
                <option className='text-tprimary' value='wrist'>
                  Wrist
                </option>
                <option className='text-tprimary' value='ankle'>
                  Ankle
                </option>
                <option className='text-tprimary' value='other'>
                  Other
                </option>
              </Field>
              <div className='absolute top-[40px] right-4 transform -translate-y-1/2 pointer-events-none'>
                <TbSquareRoundedChevronDownFilled
                  size={20}
                  className='text-tprimary'
                />
              </div>
            </div>

            <Field
              type='tel'
              name='phone'
              required
              className='pt-[20px] text-[#8e98a6] h-[60px] pr-[36px] sm:w-full bg-transparent border-b border-solid border-tprimary text-[16px]'
              placeholder='Phone number *'
            />

            <Field
              type='text'
              name='size'
              className='pt-[20px] text-[#8e98a6] h-[60px] pr-[36px] sm:w-full bg-transparent border-b border-solid border-tprimary text-[16px]'
              placeholder='What is the size of the tattoo?'
            />

            <div className='relative sm:w-full'>
              <Field
                as='select'
                className='pt-[20px] w-full bg-tsecondary h-[60px] border-b border-solid border-tprimary text-[16px] text-[#8e98a6] appearance-none cursor-pointer'
                name='gender'
              >
                <option
                  className='text-tprimary hover:text-red-700'
                  value='default'
                  disabled
                  hidden
                >
                  Gender
                </option>
                <option className='text-tprimary' value='male'>
                  Male
                </option>
                <option className='text-tprimary' value='female'>
                  Female
                </option>
              </Field>
              <div className='absolute top-[40px] right-4 transform -translate-y-1/2 pointer-events-none'>
                <TbSquareRoundedChevronDownFilled
                  size={20}
                  className='text-tprimary'
                />
              </div>
            </div>

            <div className='relative sm:w-full'>
              <Field
                as='select'
                className='pt-[20px] w-full bg-tsecondary h-[60px] border-b border-solid border-tprimary text-[16px] text-[#8e98a6] appearance-none cursor-pointer'
                name='tattooColor'
              >
                <option
                  className='text-tprimary hover:text-red-700'
                  value='default'
                  disabled
                  hidden
                >
                  What color is the tattoo?
                </option>
                <option className='text-tprimary' value='full-color'>
                  Full Color
                </option>
                <option className='text-tprimary' value='grayscale'>
                  GrayScale
                </option>
              </Field>
              <div className='absolute top-[40px] right-4 transform -translate-y-1/2 pointer-events-none'>
                <TbSquareRoundedChevronDownFilled
                  size={20}
                  className='text-tprimary'
                />
              </div>
            </div>

            <div className='w-full relative'>
              <Field
                type='datetime-local'
                min={new Date().toISOString().split('T')[0] + 'T00:00'}
                name='dateTime'
                className='pt-[20px] text-[#8e98a6] h-[60px] pr-[16px] w-full bg-transparent border-b border-solid border-tprimary text-[16px]'
              />
              <span className='w-[10px] h-[10px] absolute bg-tsecondary top-[26px] right-[3px] '></span>
              <div className='absolute top-[40px] right-2 sm:right-[11px] transform -translate-y-1/2 pointer-events-none p-[8px] date-time-mobile-icon w-[20px] h-[20px] notMobile:hidden'></div>
            </div>

            <div className='relative sm:w-full'>
              <Field
                as='select'
                className='pt-[20px] w-full bg-tsecondary h-[60px] border-b border-solid border-tprimary text-[16px] text-[#8e98a6] appearance-none cursor-pointer'
                name='coverup'
              >
                <option
                  className='text-tprimary'
                  value='default'
                  disabled
                  hidden
                >
                  Is this tattoo a coverup?
                </option>
                <option className='text-tprimary' value='yes'>
                  Yes
                </option>
                <option className='text-tprimary' value='no'>
                  No
                </option>
              </Field>
              <div className='absolute top-[40px] right-4 transform -translate-y-1/2 pointer-events-none'>
                <TbSquareRoundedChevronDownFilled
                  size={20}
                  className='text-tprimary'
                />
              </div>
            </div>

            <div className='col-span-2 mt-8 w-full'>
              <Field
                as={TextArea}
                className='py-[20px] text-[#8e98a6] pr-[36px] mb-[32px] w-full bg-transparent border-b border-solid border-tprimary text-[16px] resize-none [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-track]:bg-tsecondary [&::-webkit-scrollbar-thumb]:bg-tprimary [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-thumb]:cursor-pointer'
                name='comment'
                rows='6'
                placeholder='Other descriptions'
              />
            </div>
            <div className='d-flex flex-col notMobile:flex-row items-center justify-center  col-span-2 w-full '>
              <button
                type='submit'
                onClick={() => setSendType('email')}
                disabled={isSubmitting}
                className='tracking-[1px] col-span-2 py-3 px-5 mt-16 mx-auto bg-tprimary hover:bg-tprimary hover:opacity-90 text-tsecondary w-max-content border-none rounded-full font-medium uppercase'
              >
                Send Via Email
              </button>
              <b className='text-white mt-12'>OR</b>
              <button
                type='submit'
                onClick={() => setSendType('whatsapp')}
                disabled={isSubmitting}
                className='tracking-[1px] col-span-2 py-3 px-5 mt-16 mx-auto bg-tprimary hover:bg-tprimary hover:opacity-90 text-tsecondary w-max-content border-none rounded-full font-medium uppercase'
              >
                Send Via WhatsApp
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default BookingForm;
