import PageWrapper from '../components/basic/PageWrapper';
import MainContainer from '../components/components/ContactUs/MainContainer';

const ArtPage = () => {
  return (
    <PageWrapper>
      <MainContainer />
    </PageWrapper>
  );
};

export default ArtPage;
