import PageWrapper from '../../components/basic/PageWrapper';
import MainContainer from '../../components/components/Services/team/MainContainer';

const TeamPage = () => {
  return (
    <PageWrapper>
      <MainContainer />
    </PageWrapper>
  );
};

export default TeamPage;
