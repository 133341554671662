import React, { useState } from 'react';

// Libraries
import { Container, Image } from 'react-bootstrap';
import SwipperSlider from '../../../basic/SwipperSlider';
import SwipperSliderContain from '../../../basic/SwipperSliderContain';
const swiperData = [
  {
    img: '/assets/img/services/custom-designs/menu-2.1-slide-1.jpg',
  },
  {
    img: '/assets/img/services/custom-designs/menu-2.1-slide-2.jpg',
  },
  {
    img: '/assets/img/services/custom-designs/menu-2.1-slide-3.jpg',
  },
  {
    img: '/assets/img/services/custom-designs/menu-2.1-slide-4.jpg',
  },
  {
    img: '/assets/img/services/custom-designs/menu-2.1-slide-5.jpg',
  },
  {
    img: '/assets/img/services/custom-designs/menu-2.1-slide-6.jpg',
  },
  {
    img: '/assets/img/services/custom-designs/menu-2.1-slide-7.jpg',
  },
  {
    img: '/assets/img/services/custom-designs/menu-2.1-slide-8.jpg',
  },
  {
    img: '/assets/img/services/custom-designs/menu-2.1-slide-9.jpg',
  },
];

const MainContainer = (props) => {
  return (
    <div>
      <Container className='mt-20'>
        <div className='text-[22px] leading-9 text-[#c5c5c5]'>
          <div className='text-white font-bold below-bottom'>
            <h5 className='!mb-0 mt-28 aboveMedium:mt-0'>
              The Meaning of Tattoos and Custom Designs
            </h5>
          </div>
        </div>

        <div className='flex flex-col mt-12 text-[20px] text-[#c5c5c5]'>
          Our tattoos hold deep and personal meanings for each individual. If
          you don’t have a design in mind yet, we offer custom design services
          tailored to your specific desires and identity. This design will
          reflect your personality and convey a special meaning that speaks to
          who you are, clearly expressing your individuality.
          <br />
          <br />
          Design Process:
          <ol className='p-6 !list-decimal'>
            <li>
              Provide Details: If you don’t have a design yet, please share your
              thoughts and preferences with us. Let us know what you want the
              tattoo to represent or the meaning you wish to express (e.g.,
              luck, love, success, protection for yourself and your family,
              etc.). Share your life story and plans for the future. The
              location of the tattoo is also important, as traditional designs
              have specific placement spots that affect energy and beliefs. If
              placed correctly according to traditional methods, the tattoo is
              believed to amplify its power and significance by 100%.
            </li>
            <li>
              Design by Masters: Our experienced masters will create a design
              that is perfect for you. It’s not just about the pattern; it’s
              about reflecting your personality, identity, and the meaning you
              want your tattoo to hold.
            </li>
            <li>
              One-of-a-Kind Tattoo: Every design is created specifically for
              you. No one else will have the same tattoo. We focus on making
              your tattoo unique and meaningful, reflecting something deeply
              personal to you.
            </li>
          </ol>
          <strong className='text-white'>Why is this tattoo important?</strong>
          <br />
          The tattoo we design for you is more than just ink on skin. It
          connects with your inner strength, helping to enhance your confidence
          and belief in yourself. It serves as a symbol that reflects your
          personality and the journey of your life
        </div>
        <div className='mt-12'>
          <SwipperSliderContain
            swiperData={swiperData}
            imageType={'contain'}
            mobileHeight={'450px'}
            largeHeight={'550px'}
          />
        </div>
      </Container>
    </div>
  );
};

export default MainContainer;
