import PageWrapper from '../components/basic/PageWrapper';
import LandingSection from '../components/components/GalleryPage/LandingSection';

const HomePage = () => {
  return (
    <>
      <PageWrapper>
        <LandingSection />
      </PageWrapper>
    </>
  );
};

export default HomePage;
