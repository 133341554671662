import React, { useState } from 'react';

// Libraries
import { Container, Image } from 'react-bootstrap';
import SwipperSlider from '../../../basic/SwipperSlider';
import SwipperSliderContain from '../../../basic/SwipperSliderContain';
const swiperData = [
  {
    img: '/assets/img/services/preparations/menu-2.4-slide-0.jpg',
  },
  {
    img: '/assets/img/services/preparations/menu-2.4-slide-1.jpg',
  },
  {
    img: '/assets/img/services/preparations/menu-2.4-slide-2.jpg',
  },
  {
    img: '/assets/img/services/preparations/menu-2.4-slide-3.jpg',
  },
  {
    img: '/assets/img/services/preparations/menu-2.4-slide-4.jpg',
  },
];
const swiperData2 = [
  {
    img: '/assets/img/services/preparations/menu-2.4.3-slide-0.jpg',
  },
  {
    img: '/assets/img/services/preparations/menu-2.4.3-slide-1.jpg',
  },
  {
    img: '/assets/img/services/preparations/menu-2.4.3-slide-2.jpg',
  },
  {
    img: '/assets/img/services/preparations/menu-2.4.3-slide-3.jpg',
  },
  {
    img: '/assets/img/services/preparations/menu-2.4.3-slide-4.jpg',
  },
  {
    img: '/assets/img/services/preparations/menu-2.4.3-slide-5.jpg',
  },
];

const MainContainer = (props) => {
  return (
    <div>
      <Container className='mt-20'>
        <div className='text-[22px] leading-9 text-[#c5c5c5]'>
          <div className='text-white font-bold below-bottom'>
            <h5 className='!mb-0 mt-28 aboveMedium:mt-0'>
              Preparation and Sacred Rituals Before Receiving a Sak Yant Tattoo
            </h5>
          </div>
        </div>
        <div className='mt-8'>
          <Image
            src='/assets/img/services/preparations/menu-2.4-main.jpg'
            alt='gold'
            className='object-cover w-full flex'
          />
        </div>

        <div className='flex flex-col mt-12 text-[20px] text-[#c5c5c5]'>
          At Sak Yant Bangkok, we honor and preserve the ancient traditions of
          sacred tattooing, passed down through generations. As the
          fourth-generation guardian of this practice, I uphold the authenticity
          and sanctity of this ancient art, ensuring that every ritual is
          performed properly and respectfully.
          <br />
          <br />
          This is not just a tattoo—it is a spiritual experience, a sacred
          ceremony that connects you with higher energies, ancestral masters,
          and divine protectors. By following the proper steps, you will fully
          experience the power of Sak Yant and feel the energy flowing through
          this sacred space
          <br />
          <div className='my-8 aboveMedium:m-6'>
            <SwipperSliderContain
              swiperData={swiperData}
              imageType={'contain'}
              largeHeight={'500px'}
            />
          </div>
          Step-by-Step Ritual Process:
          <ol className='p-6 !list-decimal'>
            <li>
              <b> Paying Respect to the Spirits and Masters</b>
              <br />
              Upon arrival, our staff will guide you through a respectful
              ceremony to honor the spirits, divine protectors, and past masters
              of this sacred place. You will offer prayers and inform them of
              your intention—that today, you seek to receive a traditional Sak
              Yant and ask for their blessings and protection.
              <br />
              At this moment, take a deep breath and focus your mind. You are
              not just getting a tattoo—you are taking part in a powerful
              spiritual tradition that has been practiced for centuries.
            </li>
            <br />
            <li>
              <b>The Sak Yant Tattooing Process:</b>
              <br />
              <br />
              Now, you will enter the sacred moment of tattooing.
              <ul className='p-6 !list-disc'>
                <li>
                  Pain and endurance: Receiving a Sak Yant can be painful, but
                  this pain is part of the journey. It is a test of your
                  determination and devotion.
                </li>
                <li>
                  Spiritual energy: As the Master tattoos you, he will chant
                  ancient prayers and invoke the blessings of the ancestral
                  masters. The energy from these blessings will flow into you,
                  making the tattoo not just ink on your skin but a living
                  symbol of protection and power.
                </li>
                <li>
                  A moment of focus: Stay strong, breathe deeply, and keep your
                  intention clear in your heart.
                </li>
              </ul>
            </li>
            <li>
              <b>The Final Blessing – Connecting with the Divine</b>
              <br />
              <br />
              Once the tattooing process is complete, the final and most sacred
              moment begins.
              <ul className='p-6 !list-disc'>
                <li>
                  You will sit quietly and send your thoughts and prayers to the
                  spirits and ancestral masters.
                </li>
                <li>
                  Speak from your heart—tell them your name, where you come
                  from, and why you have chosen this Sak Yant.
                </li>
                <li>
                  Make your wish: Whether it is for protection, strength,
                  wisdom, or success, express your deepest intentions.
                </li>
                <li>
                  Pray for blessings: Not just for yourself, but for your
                  family, loved ones, and future.
                </li>
              </ul>
            </li>
          </ol>
          This moment is profoundly spiritual—it is a direct connection between
          you and the higher forces that watch over this sacred tradition.
        </div>
        <div className='aboveMedium:m-6'>
          <SwipperSliderContain
            swiperData={swiperData2}
            imageType={'contain'}
            largeHeight={'500px'}
          />
        </div>
        <div className='text-[20px] text-[#c5c5c5]'>
          <br />
          <i className='text-white'>A Ritual Like No Other</i>
          <br />
          <br />
          This is not just a tattoo parlor—it is a sacred space where tradition,
          energy, and spirituality come together. When you receive a Sak Yant
          here, you are not just getting a design on your skin; you are becoming
          part of something ancient, powerful, and deeply meaningful.
          <br />
          <br />
          Let yourself fully embrace the experience. Feel the energy, respect
          the ritual, and leave not just with a Sak Yant, but with a lifelong
          connection to its power and blessings
          <br />
          <br />
          Rules and Respect for the Sacred Place
          <br />
          <br />
          Our place is not just a tattoo studio—it is a sacred space with deep
          ancestral roots that follow ancient traditions. The rituals performed
          here are not merely artistic expressions but a spiritual connection to
          the energy of the universe. To honor this sacred place and the
          traditions it upholds, we ask that you follow these rules:
          <br />
          <br />
          To maintain the purity and sanctity of the Sak Yant ceremony, we have
          a set of rules and guidelines that all visitors must follow. These
          rules ensure that everyone experiences the ritual with full respect
          and devotion while preserving the sacred energy of this place.
          <br />
          <br />
          <strong className='text-white'>Rules and Guidelines</strong>
          <br />
          <ol className='p-6 !list-decimal'>
            <li>
              <b> A Calm and Respectful Mindset</b>
              <br />
              Please enter with a calm and open heart, ready to receive the
              sacred energy and participate in the rituals. Avoid bringing
              stress, anxiety, or negative energy into this space, as it is a
              place where we must unify our minds and spirits.
            </li>
            <br />
            <li>
              <b>Proper Attire</b>
              <br />
              Visitors must dress modestly and appropriately to show respect for
              the sacred environment. Please avoid wearing revealing or
              inappropriate clothing that may disrupt the sanctity of the space.
            </li>
            <br />

            <li>
              <b> Respect for the Rituals</b>
              <br />
              Every step of the ceremony holds deep significance. Please respect
              the entire process, be mindful of timing, and do not interrupt any
              part of the ritual. Your patience and reverence will enhance the
              spiritual experience.
            </li>
            <br />

            <li>
              <b> Maintaining Silence and Serenity</b>
              <br />
              To allow pure energy to flow, the environment must remain quiet
              and undisturbed. Please refrain from unnecessary conversations or
              movements that may disrupt others during the ceremony. Every Sak
              Yant session takes place in a setting of peace and spiritual
              focus.
              <br />
              <br />
              An Experience Beyond Tattoos
              <br />
              <br />
              This is not just a place for tattoos; it is a space where you can
              connect with sacred energy and feel the presence of ancestral
              wisdom passed down for generations.
              <br />
              <br />
              Coming here is not simply about getting a design on your body—it
              is a spiritual journey that links you to the energy of the
              universe. It is a chance to open yourself to new possibilities and
              strengthen your inner stability in life.
              <br />
              <br />
              We invite you to embrace this experience with respect, an open
              heart, and a willingness to receive the best for your journey
              ahead
            </li>
          </ol>
        </div>
      </Container>
    </div>
  );
};

export default MainContainer;
