import React, { useState } from 'react';

// Libraries
import { Container, Image } from 'react-bootstrap';

const MainContainer = (props) => {
  return (
    <div>
      <Container className='mt-20'>
        <div className='text-[22px] leading-9 text-[#c5c5c5]'>
          <div className='text-white font-bold below-bottom'>
            <h5 className='!mb-0 mt-28 aboveMedium:mt-0'>
              Hygiene and Safety Standards – Our Needles and Ink
            </h5>
          </div>
        </div>

        <div className='flex flex-col mt-12 text-[20px] text-[#c5c5c5]'>
          At Sak Yant Bangkok, your safety and hygiene are our top priorities.
          Every needle and ink used in our sacred tattooing process is brand new
          and used only once per person—we never reuse needles or ink under any
          circumstances.
          <br />
          <br />
          Needles – One-Time Use for Every Individual
          <br />
          <ul className='p-6 !list-disc'>
            <li>
              We use single-use, sterilized needles that are specially designed
              for Sak Yant tattooing.
            </li>
            <li>
              Once the tattooing process is complete, the needle is immediately
              discarded—it is never reused.
            </li>
            <li>
              Each needle is fully sanitized and prepared before use to ensure
              100% safety for our clients.
            </li>
          </ul>
          Tattoo Ink – International Safety Standards
          <br />
          <ul className='p-6 !list-disc'>
            <li>
              We use high-quality, professional-grade tattoo ink that meets
              international hygiene and safety standards.
            </li>
            <li>
              Each ink batch is prepared separately for every individual,
              ensuring there is no ink sharing between clients.
            </li>
            <li>
              The ink we use today is clean, safe, and suitable for permanent
              tattooing, ensuring long-lasting and vibrant results.
            </li>
          </ul>
          At Sak Yant Bangkok, we uphold the highest standards of safety and
          cleanliness. Our commitment is not only to preserve the sacred
          traditions of Sak Yant tattooing but also to ensure a hygienic and
          professional experience for everyone who receives a tattoo from us
        </div>
        <div className='mt-8 d-flex justify-center'>
          <Image
            src='/assets/img/services/standards/menu-2.5-main.jpg'
            alt='gold'
            className='object-cover aboveMedium:w-[50%] h-full flex'
          />
        </div>
      </Container>
    </div>
  );
};

export default MainContainer;
