import { Col, Container, Image, Row } from 'react-bootstrap';

const LandingSection = () => {
  return (
    <div className='overflow-hidden relative'>
      <Container className='relative space-y-12 mt-12'>
        <div className='text-[20px] leading-9 text-[#c5c5c5] '>
          <div className='text-white font-bold'>
            <p className='!mb-0 mt-32 aboveMedium:mt-0 text-[24px]'>
              Step Into the Hidden World of Sak Yant: A Sacred Legacy Unveiled
            </p>
            <br />
          </div>
          <div>
            Beyond mere ink and skin lies an ancient, mystical art—one
            intertwined with sacred energies, unseen forces, and forbidden
            knowledge passed down through generations. In this gallery, you will
            uncover the deeper, more enigmatic side of Sak Yant, a realm that
            transcends mere belief and enters the world of true spiritual power.
            <br />
            <br />
            For centuries, master tattooists have safeguarded these secrets,
            whispered only to their most trusted disciples. Now, for the first
            time, you are invited to step beyond the veil and witness the sacred
            artifacts, lost rituals, and arcane wisdom that have shaped the
            world of Sak Yant.
            <br />
            <br />
          </div>
          <div>
            What Hidden Mysteries Await You?
            <br />
            <br />
            <ul class='mysteries-list'>
              <li class='mystery-item'>
                <strong>The Forbidden Texts of the Ancients</strong> – We hold
                in our possession ancient manuscripts over 200 years old,
                inscribed with sacred symbols, incantations, and the
                long-guarded knowledge of Sak Yant. These texts were once
                reserved only for the chosen few, their wisdom kept from the
                uninitiated.
              </li>
              <li class='mystery-item'>
                <strong>The Tools of the Masters</strong> – See the original
                hand-forged needles, relics of past generations, once used to
                inscribe sacred markings onto warriors, shamans, and those
                seeking divine protection. Each needle carries the energy of the
                countless rituals performed before it.
              </li>
              <li class='mystery-item'>
                <strong>The Lost Art of Sacred Ink</strong> – Discover the
                ancient alchemy of Sak Yant ink, crafted from arcane
                ingredients, each drop infused with meaning and power. Some inks
                were mixed with secret herbal powders, while others contained
                charred bones or ashes of venerated masters, ensuring the spirit
                of the past lived on in every mark.
              </li>
              <br />
            </ul>
          </div>
          <div>
            <strong>The Mystical Substances of Power</strong>
            <br />
            <br />
            But the true power of Sak Yant goes beyond the ink—it is embedded in
            the very essence of the materials used. Within these sacred
            artifacts, you will find:
            <br />
            <br />
            <ul class='mysteries-list'>
              <li class='mystery-item'>
                Enchanted herbal powders used in forgotten rites, each believed
                to attract luck, protection, and supernatural energy.
              </li>
              <li class='mystery-item'>
                Bones of sacred beasts and ancient talismans, ground into fine
                dust and infused into ink to channel the strength of the
                spirits.
              </li>
              <li class='mystery-item'>
                Mystical minerals and divine stones, once believed to hold
                cosmic energy, either ground into sacred mixtures or embedded
                beneath the skin to enhance one’s spiritual connection.
              </li>
            </ul>
          </div>
          <br />
          <div>
            For those who seek more than just a tattoo—for those who yearn to
            understand the true depth of Sak Yant—this is your invitation. The
            energy of the past, the secrets of the masters, and the ancient
            forces that have shaped this sacred tradition are waiting to be
            discovered.
            <br />
            <br />
            Are You Ready to Uncover the Forbidden Knowledge of Sak Yant?
            <br />
            <br />
            Step inside. The mysteries are calling.
          </div>
        </div>
        {/* <div className=' '>
          <Image
            src='/assets/img/home-page/main-2.jpg'
            alt='gold'
            className='object-cover w-full h-full flex'
          />
        </div> */}
      </Container>
    </div>
  );
};

export default LandingSection;
