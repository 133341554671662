const popularDesignArray = [
  { img: '/assets/img/album-page/popular-design/popular-1.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-2.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-3.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-4.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-5.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-6.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-7.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-8.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-9.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-10.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-11.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-12.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-13.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-14.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-15.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-16.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-17.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-18.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-19.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-20.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-21.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-22.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-23.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-24.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-25.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-26.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-27.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-28.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-29.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-30.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-31.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-32.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-33.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-34.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-35.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-36.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-37.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-38.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-39.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-40.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-41.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-42.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-43.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-44.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-45.jpeg' },
  { img: '/assets/img/album-page/popular-design/popular-46.jpeg' },
];
const lotusArray = [
  { img: '/assets/img/album-page/lotus/lotus-1.jpeg' },
  { img: '/assets/img/album-page/lotus/lotus-2.jpeg' },
  { img: '/assets/img/album-page/lotus/lotus-3.jpeg' },
  { img: '/assets/img/album-page/lotus/lotus-4.jpeg' },
  { img: '/assets/img/album-page/lotus/lotus-5.jpeg' },
  { img: '/assets/img/album-page/lotus/lotus-6.jpeg' },
  { img: '/assets/img/album-page/lotus/lotus-7.jpeg' },
  { img: '/assets/img/album-page/lotus/lotus-8.jpeg' },
  { img: '/assets/img/album-page/lotus/lotus-9.jpeg' },
  { img: '/assets/img/album-page/lotus/lotus-10.jpeg' },
  { img: '/assets/img/album-page/lotus/lotus-11.jpeg' },
  { img: '/assets/img/album-page/lotus/lotus-12.jpeg' },
  { img: '/assets/img/album-page/lotus/lotus-13.jpeg' },
  { img: '/assets/img/album-page/lotus/lotus-14.jpeg' },
  { img: '/assets/img/album-page/lotus/lotus-15.jpeg' },
  { img: '/assets/img/album-page/lotus/lotus-16.jpeg' },
  { img: '/assets/img/album-page/lotus/lotus-17.jpeg' },
  { img: '/assets/img/album-page/lotus/lotus-18.jpeg' },
  { img: '/assets/img/album-page/lotus/lotus-19.jpeg' },
];

const tigerYantArray = [
  { img: '/assets/img/album-page/tiger-yant/tiger-yant-1.jpeg' },
  { img: '/assets/img/album-page/tiger-yant/tiger-yant-2.jpeg' },
  { img: '/assets/img/album-page/tiger-yant/tiger-yant-3.jpeg' },
  { img: '/assets/img/album-page/tiger-yant/tiger-yant-4.jpeg' },
  { img: '/assets/img/album-page/tiger-yant/tiger-yant-5.jpeg' },
  { img: '/assets/img/album-page/tiger-yant/tiger-yant-6.jpeg' },
  { img: '/assets/img/album-page/tiger-yant/tiger-yant-7.jpeg' },
  { img: '/assets/img/album-page/tiger-yant/tiger-yant-8.jpeg' },
  { img: '/assets/img/album-page/tiger-yant/tiger-yant-9.jpeg' },
  { img: '/assets/img/album-page/tiger-yant/tiger-yant-10.jpeg' },
  { img: '/assets/img/album-page/tiger-yant/tiger-yant-11.jpeg' },
  { img: '/assets/img/album-page/tiger-yant/tiger-yant-12.jpeg' },
  { img: '/assets/img/album-page/tiger-yant/tiger-yant-13.jpeg' },
  { img: '/assets/img/album-page/tiger-yant/tiger-yant-14.jpeg' },
  { img: '/assets/img/album-page/tiger-yant/tiger-yant-15.jpeg' },
  { img: '/assets/img/album-page/tiger-yant/tiger-yant-16.jpeg' },
  { img: '/assets/img/album-page/tiger-yant/tiger-yant-17.jpeg' },
  { img: '/assets/img/album-page/tiger-yant/tiger-yant-18.jpeg' },
  { img: '/assets/img/album-page/tiger-yant/tiger-yant-19.jpeg' },
  { img: '/assets/img/album-page/tiger-yant/tiger-yant-20.jpeg' },
  { img: '/assets/img/album-page/tiger-yant/tiger-yant-21.jpeg' },
  { img: '/assets/img/album-page/tiger-yant/tiger-yant-22.jpeg' },
  { img: '/assets/img/album-page/tiger-yant/tiger-yant-23.jpeg' },
  { img: '/assets/img/album-page/tiger-yant/tiger-yant-24.jpeg' },
];
const yantGaneshaArray = [
  { img: '/assets/img/album-page/yant-Ganesha/yant-Ganesha-1.jpeg' },
  { img: '/assets/img/album-page/yant-Ganesha/yant-Ganesha-2.jpeg' },
  { img: '/assets/img/album-page/yant-Ganesha/yant-Ganesha-3.jpeg' },
  { img: '/assets/img/album-page/yant-Ganesha/yant-Ganesha-4.jpeg' },
  { img: '/assets/img/album-page/yant-Ganesha/yant-Ganesha-5.jpeg' },
  { img: '/assets/img/album-page/yant-Ganesha/yant-Ganesha-6.jpeg' },
  { img: '/assets/img/album-page/yant-Ganesha/yant-Ganesha-7.jpeg' },
  { img: '/assets/img/album-page/yant-Ganesha/yant-Ganesha-8.jpeg' },
  { img: '/assets/img/album-page/yant-Ganesha/yant-Ganesha-9.jpeg' },
  { img: '/assets/img/album-page/yant-Ganesha/yant-Ganesha-10.jpeg' },
  { img: '/assets/img/album-page/yant-Ganesha/yant-Ganesha-11.jpeg' },
  { img: '/assets/img/album-page/yant-Ganesha/yant-Ganesha-12.jpeg' },
  { img: '/assets/img/album-page/yant-Ganesha/yant-Ganesha-13.jpeg' },
  { img: '/assets/img/album-page/yant-Ganesha/yant-Ganesha-14.jpeg' },
  { img: '/assets/img/album-page/yant-Ganesha/yant-Ganesha-15.jpeg' },
  { img: '/assets/img/album-page/yant-Ganesha/yant-Ganesha-16.jpeg' },
  { img: '/assets/img/album-page/yant-Ganesha/yant-Ganesha-17.jpeg' },
  { img: '/assets/img/album-page/yant-Ganesha/yant-Ganesha-18.jpeg' },
  { img: '/assets/img/album-page/yant-Ganesha/yant-Ganesha-19.jpeg' },
  { img: '/assets/img/album-page/yant-Ganesha/yant-Ganesha-20.jpeg' },
  { img: '/assets/img/album-page/yant-Ganesha/yant-Ganesha-21.jpeg' },
  { img: '/assets/img/album-page/yant-Ganesha/yant-Ganesha-22.jpeg' },
];
const porKeaArray = [
  { img: '/assets/img/album-page/por-kea/por-kea-1.jpeg' },
  { img: '/assets/img/album-page/por-kea/por-kea-2.jpeg' },
  { img: '/assets/img/album-page/por-kea/por-kea-3.jpeg' },
  { img: '/assets/img/album-page/por-kea/por-kea-4.jpeg' },
  { img: '/assets/img/album-page/por-kea/por-kea-5.jpeg' },
  { img: '/assets/img/album-page/por-kea/por-kea-6.jpeg' },
  { img: '/assets/img/album-page/por-kea/por-kea-7.jpeg' },
  { img: '/assets/img/album-page/por-kea/por-kea-8.jpeg' },
  { img: '/assets/img/album-page/por-kea/por-kea-9.jpeg' },
  { img: '/assets/img/album-page/por-kea/por-kea-10.jpeg' },
  { img: '/assets/img/album-page/por-kea/por-kea-11.jpeg' },
  { img: '/assets/img/album-page/por-kea/por-kea-12.jpeg' },
  { img: '/assets/img/album-page/por-kea/por-kea-13.jpeg' },
  { img: '/assets/img/album-page/por-kea/por-kea-14.jpeg' },
  { img: '/assets/img/album-page/por-kea/por-kea-15.jpeg' },
  { img: '/assets/img/album-page/por-kea/por-kea-16.jpeg' },
  { img: '/assets/img/album-page/por-kea/por-kea-17.jpeg' },
];
const yantOfTheDeityArray = [
  { img: '/assets/img/album-page/Yant-of-the-Deity/Yant-of-the-Deity-1.jpeg' },
  { img: '/assets/img/album-page/Yant-of-the-Deity/Yant-of-the-Deity-2.jpeg' },
  { img: '/assets/img/album-page/Yant-of-the-Deity/Yant-of-the-Deity-3.jpeg' },
  { img: '/assets/img/album-page/Yant-of-the-Deity/Yant-of-the-Deity-4.jpeg' },
  { img: '/assets/img/album-page/Yant-of-the-Deity/Yant-of-the-Deity-5.jpeg' },
  { img: '/assets/img/album-page/Yant-of-the-Deity/Yant-of-the-Deity-6.jpeg' },
  { img: '/assets/img/album-page/Yant-of-the-Deity/Yant-of-the-Deity-7.jpeg' },
  { img: '/assets/img/album-page/Yant-of-the-Deity/Yant-of-the-Deity-8.jpeg' },
  { img: '/assets/img/album-page/Yant-of-the-Deity/Yant-of-the-Deity-9.jpeg' },
  { img: '/assets/img/album-page/Yant-of-the-Deity/Yant-of-the-Deity-10.jpeg' },
  { img: '/assets/img/album-page/Yant-of-the-Deity/Yant-of-the-Deity-11.jpeg' },
  { img: '/assets/img/album-page/Yant-of-the-Deity/Yant-of-the-Deity-12.jpeg' },
  { img: '/assets/img/album-page/Yant-of-the-Deity/Yant-of-the-Deity-13.jpeg' },
  { img: '/assets/img/album-page/Yant-of-the-Deity/Yant-of-the-Deity-14.jpeg' },
];
const hanumanArray = [
  { img: '/assets/img/album-page/hanuman/hanuman-1.jpeg' },
  { img: '/assets/img/album-page/hanuman/hanuman-2.jpeg' },
  { img: '/assets/img/album-page/hanuman/hanuman-3.jpeg' },
  { img: '/assets/img/album-page/hanuman/hanuman-4.png' },
  { img: '/assets/img/album-page/hanuman/hanuman-5.jpeg' },
  { img: '/assets/img/album-page/hanuman/hanuman-6.jpeg' },
  { img: '/assets/img/album-page/hanuman/hanuman-7.jpeg' },
  { img: '/assets/img/album-page/hanuman/hanuman-8.png' },
  { img: '/assets/img/album-page/hanuman/hanuman-9.jpeg' },
  { img: '/assets/img/album-page/hanuman/hanuman-10.jpeg' },
  { img: '/assets/img/album-page/hanuman/hanuman-11.jpeg' },
  { img: '/assets/img/album-page/hanuman/hanuman-12.jpeg' },
  { img: '/assets/img/album-page/hanuman/hanuman-13.jpeg' },
  { img: '/assets/img/album-page/hanuman/hanuman-14.jpeg' },
  { img: '/assets/img/album-page/hanuman/hanuman-15.jpeg' },
  { img: '/assets/img/album-page/hanuman/hanuman-16.jpeg' },
  { img: '/assets/img/album-page/hanuman/hanuman-17.png' },
  { img: '/assets/img/album-page/hanuman/hanuman-18.png' },
  { img: '/assets/img/album-page/hanuman/hanuman-19.jpeg' },
  { img: '/assets/img/album-page/hanuman/hanuman-20.jpeg' },
];
const nagaYantArray = [
  { img: '/assets/img/album-page/naga-yant/naga-yant-1.jpeg' },
  { img: '/assets/img/album-page/naga-yant/naga-yant-2.jpeg' },
  { img: '/assets/img/album-page/naga-yant/naga-yant-3.jpeg' },
  { img: '/assets/img/album-page/naga-yant/naga-yant-4.jpeg' },
  { img: '/assets/img/album-page/naga-yant/naga-yant-5.jpeg' },
  { img: '/assets/img/album-page/naga-yant/naga-yant-6.jpeg' },
  { img: '/assets/img/album-page/naga-yant/naga-yant-7.jpeg' },
  { img: '/assets/img/album-page/naga-yant/naga-yant-8.jpeg' },
  { img: '/assets/img/album-page/naga-yant/naga-yant-9.jpeg' },
];
export {
  popularDesignArray,
  lotusArray,
  tigerYantArray,
  yantGaneshaArray,
  porKeaArray,
  yantOfTheDeityArray,
  hanumanArray,
  nagaYantArray,
};
