import React, { useState } from 'react';

// Libraries
import { Container, Image } from 'react-bootstrap';
import GoogleMap from '../../../Litho/Components/GoogleMap/GoogleMap';
import { FaInstagram, FaWhatsapp } from 'react-icons/fa';

const MainContainer = (props) => {
  return (
    <div>
      <Container className='mt-20'>
        <div className='text-[22px] leading-9 text-[#c5c5c5]'>
          <div className='text-white font-bold below-bottom'>
            <h3 className='!mb-0 mt-28 aboveMedium:mt-0'>Contact Us</h3>
          </div>
        </div>

        <div className='flex flex-col items-center gap-12 mt-12 text-[20px] text-[#c5c5c5]'>
          <i>
            Sak Yant is more than just an art form—it is a connection to energy,
            faith, and deep personal meaning. We understand that you may have
            questions or seek guidance in choosing the design that best aligns
            with you.
          </i>
          Here, we pay close attention to every step, from selecting the right
          Yant to ensuring that the sacred process is performed correctly. If
          you are interested or have any inquiries, feel free to contact us. We
          are here to provide honest advice tailored to your unique journey.
          <br />
          <br />
          Reach out to us and begin your path to empowerment and protection.
          <br />
          <br />
        </div>
        <div className='d-flex flex-col gap-16'>
          <div>
            <div className='text-white font-bold below-bottom'>
              <h6 className='!mb-0'>Contact Details</h6>
            </div>
            <div className='mt-8 text-[24px] leading-9 text-[#c5c5c5] d-flex gap-64 sxl:flex-col sxl:justify-start sxl:items-start sxl:gap-12'>
              <div>Email: toanucha111@gmail.com</div>
              <div>Phone: +66 65 740 6999</div>
            </div>
          </div>
          <div className='mt-6 flex justify-between sxl:flex-col sxl:justify-start sxl:items-start sxl:gap-12'>
            <div className='w-[50%] sxl:w-[100%]'>
              <div className='text-white font-bold below-bottom'>
                <h6 className='!mb-0'>Contact us on WhatsApp</h6>
              </div>
              <div className='mt-12 mb-12'>
                <a
                  href='https://wa.me/+66657406999'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <button className='bg-[#f5f5f5] text-black  py-3 px-4 rounded-full flex items-center gap-3 notMobile:text-[24px] text-[18px] '>
                    <FaWhatsapp
                      size={32}
                      className='hidden notMobile:block text-green-500'
                    />
                    <FaWhatsapp
                      size={24}
                      className='block notMobile:hidden text-green-500'
                    />
                    Sakyant Master Tor
                  </button>
                </a>
              </div>
            </div>
            <div className='w-[50%] sxl:w-[100%]'>
              <div className='text-white font-bold below-bottom'>
                <h6 className='!mb-0'>Contact us on Instagram</h6>
              </div>
              <div className='mt-12 mb-12'>
                <a
                  href='https://www.instagram.com/sakyant_bangkok?igsh=ZnNqd2gxb2JvZmY5&utm_source=qr'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <button className='bg-[#f5f5f5] text-black  py-3 px-4 rounded-full flex items-center gap-3 notMobile:text-[24px] text-[18px] '>
                    <FaInstagram
                      size={32}
                      className=' hidden notMobile:block text-pink-500'
                    />
                    <FaInstagram
                      size={24}
                      className='block notMobile:hidden text-pink-500'
                    />
                    sakyant_bangkok
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className=''>
            <GoogleMap
              className='h-[500px] xs:h-[300px]'
              location='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3873.1383931700293!2d100.68408679999999!3d13.890671999999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311d7ddbef6f247b%3A0xc48d768f67675329!2sSakyant%20Bangkok%20Real%20sakyant%20Traditional%20master%20to!5e0!3m2!1sen!2s!4v1742114087802!5m2!1sen!2s'
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default MainContainer;
