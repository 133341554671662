import { Col, Container, Row } from 'react-bootstrap';
import FooterMenu, {
  Footer as LithoFooter,
} from '../../Litho/Components/Footers/Footer';
import FooterData from '../../Litho/Components/Footers/FooterData';
import { Link } from 'react-router-dom';
const FooterMenuData = [FooterData[0], FooterData[3]];

const Footer = () => {
  return (
    <LithoFooter
      parallax={{ desktop: false, md: false }}
      theme={'bg-[#101010]'}
      className='creative-footer border-t border-[#ffffff1a] mt-20'
    >
      <div className='py-[4%] lg:py-[8%] md:py-[50px]'>
        <Container>
          <Row className='justify-start md:text-start'>
            <Col
              xl={{ span: 5, order: 0 }}
              md={8}
              sm={{ span: 6, order: 1 }}
              className='lg:mb-[50px] xs:mb-[25px]'
            >
              <Link aria-label='Follow' to='/' className='mb-[20px] block'>
                <img
                  src='/assets/img/bangkok-logo.png'
                  alt='logo'
                  width='320'
                  height='52'
                  className='max-h-min'
                />
              </Link>
              <p className='font-serif font-light  mb-0 md:w-[80%] sm:w-[85%] text-xlg leading-[36px] -tracking-[0.5px] xs:w-11/12 text-[#aaaaaa] lg:text-lg lg:leading-[26px]'>
                Sakyant is an invitation to the Triple Gem, namely the Buddha,
                the Dharma and the Sangha into us.
              </p>
              <div className='mt-[20px] bg-tprimary w-max px-4 py-3 text-lg font-black text-black'>
                <a href='/services/booking'>BOOK AN APPOINTMENT</a>
              </div>
            </Col>
            <Col
              xl={{ span: 4, order: 0 }}
              md={8}
              sm={{ span: 8, order: 1 }}
              className='lg:mb-[50px] xs:mb-[25px]'
            >
              <div className='text-white font-bold below-bottom'>
                <h5 className='!mb-0'>CONTACT US</h5>
              </div>
              <div className='flex flex-col gap-4 mt-8'>
                <div className='text-lg leading-10 border-b-2 border-[#747474] pb-3 w-fit'>
                  <div className='text-white'>Email</div>
                  <div className='text-[#aaaaaa]'>Toanucha111@gmail.com</div>
                </div>
                <div className='text-lg leading-10 border-b-2 border-[#747474] pb-3 w-fit'>
                  <div className='text-white'>Phone</div>
                  <div className='text-[#aaaaaa]'>+66 65 740 6999 </div>
                </div>
                <div className='text-lg leading-10 pb-3'>
                  <div className='text-white'>Location</div>
                  <div className='text-[#aaaaaa]'>
                    50 968 Chatu Chot 20 Alley, Ao Ngoen, Sai Mai, Bangkok
                    10220, Thailand
                  </div>
                </div>
              </div>
            </Col>
            <Col
              xl={{ span: 3, order: 0 }}
              md={8}
              sm={{ span: 6, order: 1 }}
              className='lg:mb-[25px] xs:mb-[25px]'
            >
              <div className='text-white font-bold below-bottom'>
                <h5 className='!mb-0'>WORKING DAYS</h5>
              </div>
              <div className='flex flex-col gap-4 mt-8'>
                <div className='text-lg leading-10 border-b-2 border-[#747474] pb-3 w-fit'>
                  <div className='text-white'>Daily</div>
                  <div className='text-[#aaaaaa]'>8am – 8pm</div>
                </div>
                <div className='text-lg leading-10 pb-3'>
                  <div className='text-white'>Appointments</div>
                  <div className='text-[#aaaaaa]'>24 Hours a Day!</div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </LithoFooter>
  );
};

export default Footer;
