import React, { useState } from 'react';

// Libraries
import { Container, Image } from 'react-bootstrap';
import SwipperSlider from '../../../basic/SwipperSlider';
import SwipperSliderContain from '../../../basic/SwipperSliderContain';
const swiperData = [
  {
    img: '/assets/img/services/team/menu-2.6-slide-4.jpg',
  },
  {
    img: '/assets/img/services/team/menu-2.6-slide-2.jpg',
  },
  {
    img: '/assets/img/services/team/menu-2.6-slide-1.jpg',
  },
  {
    img: '/assets/img/services/team/menu-2.6-slide-3.jpg',
  },

  {
    img: '/assets/img/services/team/menu-2.6-slide-5.jpg',
  },
  {
    img: '/assets/img/services/team/menu-2.6-slide-6.jpg',
  },
  {
    img: '/assets/img/services/team/menu-2.6-slide-7.jpg',
  },
  {
    img: '/assets/img/services/team/menu-2.6-slide-8.jpg',
  },
];

const MainContainer = (props) => {
  return (
    <div>
      <Container className='mt-20'>
        <div className='text-[22px] leading-9 text-[#c5c5c5]'>
          <div className='text-white font-bold below-bottom'>
            <h5 className='!mb-0 mt-28 aboveMedium:mt-0'>
              Our Work Schedule and Team
            </h5>
          </div>
        </div>

        <div className='flex flex-col mt-12 text-[20px] text-[#c5c5c5]'>
          <b className='text-white'> Work Time and Waiting Time</b>
          Typically, a small tattoo, such as five rows of characters in a single
          line along the spine or a nine-peak yantra, will take around 1 hour
          for the tattooing process and related rituals. Larger tattoos, such as
          a pair of tigers, Hanuman, Ganesh, or other large designs, will take
          approximately 1 hour 45 minutes to 2 hours 30 minutes.
          <br />
          <br />
          We understand that your time is valuable, and we are committed to
          providing the best service. However, since the tattoos we create are
          not just designs on the skin but a form of detailed and careful design
          work, we may sometimes need extra time to consider the appropriate
          tattoo style for each individual.
          <br />
          <br />
          <b className='text-white'> On-Site Design Adjustments</b>
          Sometimes, upon arrival, customers may need to wait as we design or
          adjust the tattoo style to align with their desires and personal
          identity at that moment. This can occasionally extend the time
          slightly beyond the initial estimated period.
          <br />
          <br />
          <b className='text-white'> Attention to Detail</b>
          Tattooing here is not a rushed process but one carried out with great
          care and intention to create the best possible outcome for each
          client. We kindly ask for your understanding that the time spent in
          planning and executing the tattoo may vary based on the situation.
          <br />
          <br />
          <b className='text-white'> Effort to Control Time </b>
          We are attentive to every appointment and strive to control time as
          much as possible. However, the most important thing is ensuring you
          receive the best tattoo and complete ritual experience tailored to
          you.
          <br />
          <br />
          We appreciate your understanding and cooperation in helping us create
          the best possible experience for you.
          <br />
          <div className='m-8 d-flex justify-center'>
            <Image
              src='/assets/img/services/team/menu-2.6-slide-0.jpg'
              alt='gold'
              className='object-cover aboveMedium:w-[50%] h-full flex'
            />
          </div>
          <b className='text-white'> Our Team’s Care and Support</b>
          At our place, we place great importance on customer care. When you
          come to us, you will be looked after by our staff, who will assist and
          guide you through every step of the ceremony and tattooing process.
          <br />
          <br />
          <b className='text-white'> Assistance by Our Staff</b>
          <br />
          Our staff will assist you in various ways, such as:
          <br />
          <ul className='p-6 !list-disc'>
            <li>
              Leading you in paying respects and showing reverence to the master
              and sacred entities.
            </li>
            <li>
              Helping to stretch the skin and clean your tattoo during the
              process.
            </li>
            <li>
              Explaining each step clearly so you can fully immerse yourself in
              the ritual atmosphere.
            </li>
          </ul>
          <b className='text-white'>Communication with Our Staff</b>
          Please be aware that some of our staff may not be fluent in English or
          other languages. However, everyone is dedicated and committed to
          providing the best experience for you at our sacred Thai tattoo
          studio.
          <br />
          <br />
          Everyone in our team is a student currently learning the art of magic
          and tattooing, still in the basic stages of their education. They do
          this work out of volunteer spirit and do not receive any salaried
          compensation.
          <br />
          <br />
          <b className='text-white'>Photography and Privacy</b>
          Our team is ready to assist with taking photos or videos in any angle
          you prefer. If you wish to maintain privacy and do not want photos or
          videos to be posted on social media, please notify us directly, and
          you can specify the angles in which you would like to have photos
          taken.
          <br />
          <br />
          <b className='text-white'>Tipping and Support</b>
          If you are satisfied with the service and care provided by our staff,
          you are welcome to give them a tip. The tips you provide will be an
          encouragement for them and help them develop their skills and
          dedication in serving future customers. They will receive the tip
          after the work is completed at the end of the day.
          <br />
          <br />
          We appreciate your understanding and support of our team. They are all
          committed to ensuring you have the best experience possible and that
          your tattoo journey is memorable.
        </div>
        <div className='mt-4'>
          <SwipperSliderContain
            swiperData={swiperData}
            imageType={'contain'}
            largeHeight='500px'
          />
        </div>
      </Container>
    </div>
  );
};

export default MainContainer;
