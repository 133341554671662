import { Container, Image } from 'react-bootstrap';
import HistoryHeader from './HistoryHeader';
import HistoryCollage from './HistoryCollage';

const MainContainer = () => {
  return (
    <div>
      <HistoryHeader />
      <Container className='mt-12'>
        <div className='text-[20px] leading-9 text-[#c5c5c5] sm:text-[16px]'>
          <div className='text-white font-bold below-bottom'>
            <h5 className='!mb-0'>What is Sakyant?</h5>
          </div>
          <div>
            Sakyant is an invitation to the Triple Gem, namely the Buddha, the
            Dharma and the Sangha into us.
            <br />
            <br />
            1) Buddha being the highest above all, in and on our head.
            <br />
            <br />
            2) Inviting the parents. To come to the left and right of our
            shoulders, for them to protect and take care of us because there is
            no best blessing besides parents.
            <br />
            <br />
            3) Invitation of the teachers, who performed the ceremony in the
            middle of the heart. As when, you do something, think of the teacher
            first, which will come to help you for things that you’re are doing,
            making such things smoother and making you successful in your
            endeavors.
            <br />
            <br />
            4) The invitation of Yantras, characters, spells, and magic (Wicha)
            to cover the whole body. To protect and to heal the body from all
            harm, danger and inauspicious calamities which we might meet each
            day. Safety from all dangers, to be merciful and be loved by all.
            The above are just some examples, which in general, people seek for,
            however, the sakyant can also be tailored to your specific needs if
            required.
            <br />
            <br />
            It can be seen that after receiving the Sakyant, you must remember
            the four things mentioned above. Always remember to keep praying,
            meditating and dedicating merits to teachers, that has passed away,
            in which this magical sakyant has been passed down from. The owner
            of the sakyant, has to keep these dear to his heart and his mind
            from the past to the present, so that this magical sakyant, will
            help bless you. As remembering and paying homage to teachers and
            masters are the most important aspect in any faith.
            <br />
            <br />
            And if always remembered, whenever you require any assistance or
            advice, the teachers and masters will be there for you.
            <br />
            <br />
            However, after mentioning the above, one must also be mindful, that
            this ancient art of sakyant is done in the right way, and that the
            master whom you seek does indeed possess such knowledge and wicha in
            him, not just anyone whom copy and pastes sakyant pictures and only
            knows how to do a sakyant tattoo. As all sakyants, must be activated
            by a certain spell and a certain wicha, knowing how to tattoo and
            copying just a similar sakyant picture and getting anyone to ink it
            on your body, would not only not help you, but bring you down.
            <br />
            <br />
            Also always note that, not remembering the teachers, not praying to
            monks, meditating and not having faith, it’s like hurting yourself
            without any benefit.
          </div>
        </div>
        <div className='m-8'>
          <Image
            src='/assets/img/home-page/main-2.jpg'
            alt='gold'
            className='object-cover w-full h-full flex'
          />
        </div>
        <div className='m-8'>
          <Image
            src='/assets/img/home-page/main-3.jpeg'
            alt='gold'
            className='object-cover w-full h-full flex'
          />
        </div>

        <div className='text-[20px] leading-9 text-[#c5c5c5] sm:text-[16px] mt-12'>
          <div className='text-white font-bold below-bottom'>
            <h5 className='!mb-0'>Master Arjan Tor</h5>
          </div>
          <div>
            <br />
            All Sakyant, given by Arjan Tor at his Samnak, are all traditional
            and follow the olden scriptures and lana wicha being passed down
            since 200 years ago.
            <br />
            <br />
            No form of modified sakyants or any other sakyants, self designed or
            modified otherwise, not in the teachings of Arjan Tor, will be
            accepted in Arjan Tor’s samnak.
            <br />
            <br />
            Arjan Tor, prides himself in his knowledge of sakynant and wicha,
            which was traditionally passed down and learnt through all his
            Masters, in the traditional way and ancient forms.
            <br />
            <br />
            You should consult personally with Arjan Tor, on the issues that you
            might be facing in life or what aspects of life you would like to
            focus on and improve.
            <br />
            Arjan Tor, would then discuss a specific or even if required a
            specially customised sakyant to suit your requirements, in order for
            you to achieve the best results in life after getting the sakyant.
            <br />
            <br />
            Arjan Tor, prides himself in helping all whom seek him, and making
            sure whatever sakyant being inked onto your body would be
            efficacious, and help you in your life, be it in your work, family,
            or even spiritually.
            <br />
            <br />
            Arjan Tor, also prides himself in learning everything and doing
            everything in the traditional ways which has been passed down over
            to him for generations upon generations from famous masters, wanting
            all his sakyants wicha, when placed on the body of those seeking
            them to help, guide them and increase aspects of their life.
            <br />
            <br />
          </div>
        </div>

        <div className='text-[20px] leading-9 text-[#c5c5c5] sm:text-[16px] mt-12'>
          <div className='text-white font-bold below-bottom'>
            <h5 className='!mb-0'>History and Background of Arjan Tor</h5>
          </div>
          <div>
            <br />
            Before Arjan Tor’s mother was pregnant with Arjan Tor, she had a
            dream that a monk came, and disappeared into her womb. For 9
            consecutive days straight, this same dream occurred, which made
            Arjan Thor’s mother realize that something miraculous would happen
            to her. Not long after, she became pregnant with him.
            <br />
            When Arjan Tor was 2 years old, his father and mother took him to
            the monks whom were his father’s teachers. When the About of the
            temple saw Arjan Thor’s face, he said that this child would grow up,
            only to these 2 paths in life:
            <br />
            <div className='leading-10 my-3 ml-5'>
              1) To be a famous white robe master in Thailand
              <br />
              2) To become a thief
            </div>
            His parents kept these words from the About of the temple close to
            their hearts. Not soon after at the age of 4, Arjan Tor’s Father had
            sent him off as a disciple of Luang Por Uttama, in the Kanchanaburi
            Province.
            <br />
            Luang Por Uttama saw Arjan Tor and said that when he grew up, he
            would definitely become a Great White Robe Master, mastering vast
            wicha (ancient magic), which would enable him to help the lives of
            many, in seek of faith and help to their lives.
            <br />
            Luang Por Uttama, thereafter took out the betel nut that he was
            chewing from his mouth, and put it into the mouth of Arjan Tor to
            consume.
            <br />
            Arjan Tor, was always interested in the arts of magic (Wicha) and
            prayer spells, since he was young. He practiced the arts of,
            chanting of various spells ever since Luang Por Uttama took him in
            under his arm. Reaching the age of 13 years old, Arjan Tor started
            learning to write and read Yantra with another master Ajarn Suchat
            and Luang Ta Pradit.
            <br />
            Thereafter mastering the arts of Sakyant (Traditional Tailsman).
            <br />
            Having such vast talents in Wicha and Sakyant, he seek to empower
            himself even more on such knowledge.
            <br />
            He thereafter, went on many trips to seek teachings and knowledge
            from various other masters both the North and the South of Thailand.
            In Northeast of Thailand, to study various other magic and wicha,
            and was taken under the arm of a famous master till date, Master
            Arjan Kaew.
            <br />
            <br />
            Master Arjan Keaw imparted all of his knowledge to Arjan Tor, and in
            the end took him in as a Son, due to Arjan Thor’s kindness which he
            has always portrayed throughout his life even till today, to always
            remember his roots and taking care of all his masters in which he
            always respected and indebted to.
            <br />
            <br />
            Being a Master till date, Arjan Thor, has always remembered his
            teachings and, one of the most important are that to always take
            care of your teachers and not to be greedy, when the time is right,
            all will definitely be well.
            <br />
            <br />
            Arjan Tor, is well versed and famous for
            <br /> 1. Sakyant <br />
            2. Driving away demons/black magic/ghosts/various spirits <br />
            3. Na Natong (which is an ancient art of Charming and from that of
            108 pieces of gold foil being pasted and chanted throughout the
            body)
            <br /> 4. Treating pain in the body
            <br /> 5. Destiny of changing of Luck from bad to good
            <br /> 6. Summoning of wealth (Business, Work, New Ventures)
            <br /> Whether casting a spell, Sakyant, or any other matters
            pertaining to the ancient art and magic of Wicha, Arjan Tor, has
            learnt it all and well versed in all magic, which he have learnt
            form many famous Masters and Monks throughout his lifetime, and
            gifted in them since a child.
            <br />
            <br /> At present, Arjan Tor opens his office to perform various
            ceremonies, in Bangkok, Thailand, and maintains all ancient,
            traditional Sakyant styles, and old lana magic, which was passed
            down to him. <br />
            <br />
            Arjan Tor does not alter or take short cuts, that are not originals,
            whilst performing these ancient arts of sakyant, ceremonies or
            wicha, which has been passed down from generations since more than
            200 years ago.
            <br />
            <br /> This is what made Arjan Tor, highly sought after in Thailand,
            by all locals.
            <br />
            <br />
          </div>
        </div>
        <HistoryCollage />

        <div className='flex flex-col items-center gap-12 mt-12'>
          <Image
            src='/assets/img/history-page/history-8.jpg'
            alt='history'
            className='object-cover flex'
          />
          <Image
            src='/assets/img/history-page/history-9.jpeg'
            alt='history'
            className='object-cover flex'
          />
          <Image
            src='/assets/img/history-page/history-10.jpeg'
            alt='history'
            className='object-cover flex'
          />
          <Image
            src='/assets/img/history-page/history-11.jpeg'
            alt='history'
            className='object-cover flex'
          />
          <Image
            src='/assets/img/history-page/history-12.jpeg'
            alt='history'
            className='object-cover flex'
          />
          <Image
            src='/assets/img/history-page/history-13.jpeg'
            alt='history'
            className='object-cover flex'
          />
          <Image
            src='/assets/img/history-page/history-14.jpeg'
            alt='history'
            className='object-cover flex'
          />
          <Image
            src='/assets/img/history-page/history-15.jpeg'
            alt='history'
            className='object-cover flex'
          />
          <Image
            src='/assets/img/history-page/history-16.jpeg'
            alt='history'
            className='object-cover flex'
          />
        </div>
      </Container>
    </div>
  );
};

export default MainContainer;
