import React, { useState } from 'react';
import artSlider from './ArtSlider';
// Libraries
import { Container, Image } from 'react-bootstrap';
import SwipperSlider from '../../basic/SwipperSlider';
import SwipperSliderContain from '../../basic/SwipperSliderContain';

const artImageData = [
  {
    img: '/assets/img/art-page/art-1.jpg',
  },

  {
    img: '/assets/img/art-page/art-3.jpg',
  },
  {
    img: '/assets/img/art-page/art-4.png',
  },
  {
    img: '/assets/img/art-page/art-5.png',
  },
  {
    img: '/assets/img/art-page/art-6.jpg',
  },
  {
    img: '/assets/img/art-page/art-7.jpg',
  },
  {
    img: '/assets/img/art-page/art-8.jpg',
  },
  {
    img: '/assets/img/art-page/art-9.png',
  },
  {
    img: '/assets/img/art-page/art-10.png',
  },
  {
    img: '/assets/img/art-page/art-11.jpg',
  },
  {
    img: '/assets/img/art-page/art-12.png',
  },
  {
    img: '/assets/img/art-page/art-13.png',
  },
  {
    img: '/assets/img/art-page/art-15.jpg',
  },
  {
    img: '/assets/img/art-page/art-16.jpg',
  },
  {
    img: '/assets/img/art-page/art-17.jpg',
  },
  {
    img: '/assets/img/art-page/art-18.jpg',
  },
  {
    img: '/assets/img/art-page/art-19.jpg',
  },
];

const MainContainer = (props) => {
  return (
    <div>
      <Container className='mt-20'>
        <div className='text-[22px] leading-9 text-[#c5c5c5]'>
          <div className='text-white font-bold below-bottom'>
            <h3 className='!mb-0 mt-28 aboveMedium:mt-0'>ARTWORK</h3>
          </div>
        </div>
        <div className='mt-4 aboveMedium:h-[600px] aboveMedium:w-full d-flex aboveMedium:justify-center'>
          <Image
            src={'/assets/img/art-page/art-23.jpg'}
            alt='artwork'
            className='object-cover h-full'
          />
        </div>
        <div className='mt-8 text-[20px] text-[#aaaaaa]'>
          <b className='text-white'>Sak Yant Tattoo Portfolio Explanation</b>
          <br />
          This album showcases completed Sak Yant tattoos, offering a diverse
          collection of designs. It includes traditional, ancient patterns as
          well as modern interpretations that maintain the sacred energy and
          principles of the original designs. We also feature personalized
          tattoos, where each design is uniquely created to reflect the
          individual’s personality and intentions.
          <br />
          <ol className='p-6 !list-decimal'>
            <li>
              <b> Traditional Designs:</b> These tattoos are based on ancient
              patterns passed down through generations. They represent timeless
              symbols and energies, deeply rooted in Thai culture and spiritual
              practices.
            </li>
            <li>
              <b>Modern Adaptations:</b> While these designs are inspired by
              traditional Sak Yant, they have been creatively modernized to suit
              contemporary aesthetics, all while keeping the original sacred
              essence intact.
            </li>
            <li>
              <b>Custom Designs:</b>These tattoos are individually crafted,
              specifically tailored to each person. The designs are personalized
              based on your goals, energy, and character, ensuring a truly
              unique and meaningful experience.
            </li>
          </ol>
          Each tattoo is not just a piece of art; it’s a spiritual experience
          meant to connect you with higher energies, provide protection, and
          guide you in your life journey. We hope you enjoy exploring the
          variety of designs and finding one that speaks to your soul
          <br />
          <br />
          If you’re interested, you can copy the image and send it to us for
          inquiries, but we would like to inform you about our contact and
          response policies.
          <br />
          <br />
          Here, we value every question and request from you, as Sak Yant is not
          just an art on the body, but also involves deep meaning, energy, and
          personal suitability. All messages sent through this website will be
          personally answered by the Master.
          <br />
          <br />
          Since the details of Sak Yant need to be explained by someone with
          direct knowledge, responses may take a little time. This is because
          the Master must review and ensure that the advice provided matches
          your true needs and is in line with the principles of Sak Yant.
          <br />
          <div className='mt-8'>
            <SwipperSliderContain
              swiperData={artSlider}
              largeHeight={'600px'}
              mobileHeight={'350px'}
              dynamic={true}
            />
          </div>
          <br />
          <b className='text-white'>Contact Information:</b>
          <ul className='p-6 !list-disc'>
            <li>
              If you send an email or message through the website, please be
              patient. Normally, you will receive a response within 12 hours.
            </li>
            <li>
              For a faster response, you can contact us via WhatsApp, where
              replies are quicker.
            </li>
          </ul>
          <b className='text-white'>Why the Master personally responds:</b>
          <ol className='p-6 !list-decimal'>
            <li>
              Accuracy in Information – The content about Sak Yant is detailed
              and complex, requiring deep understanding. Someone who hasn’t
              studied directly may give unclear or incorrect answers.
            </li>
            <li>
              Design Customization – The Master must analyze the suitability of
              the Yant according to each individual’s energy.
            </li>
            <li>
              Care throughout the Process – To ensure that you receive the best
              experience and truly understand the meaning behind the Yant you
              will receive.
            </li>
          </ol>
          If you don’t receive a response within the expected time, rest assured
          that your message hasn’t been overlooked. Every question is important,
          and we will reply as soon as possible.
          <br />
          <br />
          Thank you for understanding and trusting us. We are ready to provide
          guidance and care to ensure you have the best experience possible
        </div>

        <div className=' mt-12'>
          <SwipperSliderContain
            swiperData={artImageData}
            imageType={'contain'}
            largeHeight={'600px'}
            mobileHeight={'350px'}
            dynamic={true}
          />
          {/* {artImageData.map((image, index) => (
            <Image
              key={index}
              src={image.src}
              alt='artwork'
              className='object-cover flex'
            />
          ))} */}
        </div>
      </Container>
    </div>
  );
};

export default MainContainer;
