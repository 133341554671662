import PageWrapper from '../../components/basic/PageWrapper';
import BookingForm from '../../components/components/BookingPage/BookingForm';
import MainContainer from '../../components/components/BookingPage/MainContainer';
import ContactInfo from '../../components/basic/ContactInfo';
const BookingPage = () => {
  return (
    <PageWrapper>
      <MainContainer />
      <BookingForm />
      <ContactInfo />
    </PageWrapper>
  );
};

export default BookingPage;
