import { Container, Image } from 'react-bootstrap';
import {
  popularDesignArray,
  lotusArray,
  tigerYantArray,
  yantGaneshaArray,
  porKeaArray,
  yantOfTheDeityArray,
  hanumanArray,
  nagaYantArray,
} from './AlbumData';
import SwipperSlider from '../../basic/SwipperSlider';
import SwipperSliderContain from '../../basic/SwipperSliderContain';

// const albumImageData = [
//   { src: '/assets/img/album-page/album-1.jpg' },
//   { src: '/assets/img/album-page/album-2.jpg' },
//   { src: '/assets/img/album-page/album-3.jpg' },
//   { src: '/assets/img/album-page/album-4.jpg' },
//   { src: '/assets/img/album-page/album-5.jpg' },
//   { src: '/assets/img/album-page/album-6.jpg' },
//   { src: '/assets/img/album-page/album-7.jpg' },
//   { src: '/assets/img/album-page/album-8.jpg' },
//   { src: '/assets/img/album-page/album-9.jpg' },
//   { src: '/assets/img/album-page/album-10.jpg' },
//   { src: '/assets/img/album-page/album-11.jpg' },
//   { src: '/assets/img/album-page/album-12.jpg' },
//   { src: '/assets/img/album-page/album-13.jpg' },
//   { src: '/assets/img/album-page/album-14.jpg' },
//   { src: '/assets/img/album-page/album-15.jpg' },
//   { src: '/assets/img/album-page/album-16.jpg' },
//   { src: '/assets/img/album-page/album-17.jpg' },
//   { src: '/assets/img/album-page/album-18.jpg' },
//   { src: '/assets/img/album-page/album-19.jpg' },
//   { src: '/assets/img/album-page/album-20.jpg' },
//   { src: '/assets/img/album-page/album-21.jpg' },
//   { src: '/assets/img/album-page/album-22.jpg' },
//   { src: '/assets/img/album-page/album-23.jpg' },
//   { src: '/assets/img/album-page/album-24.jpg' },
//   { src: '/assets/img/album-page/album-25.jpg' },
//   { src: '/assets/img/album-page/album-26.jpg' },
//   { src: '/assets/img/album-page/album-27.jpg' },
//   { src: '/assets/img/album-page/album-28.jpg' },
//   { src: '/assets/img/album-page/album-29.jpg' },
//   { src: '/assets/img/album-page/album-30.jpg' },
//   { src: '/assets/img/album-page/album-31.jpg' },
//   { src: '/assets/img/album-page/album-32.jpg' },
//   { src: '/assets/img/album-page/album-33.jpg' },
//   { src: '/assets/img/album-page/album-34.jpg' },
//   { src: '/assets/img/album-page/album-35.jpg' },
//   { src: '/assets/img/album-page/album-36.jpg' },
//   { src: '/assets/img/album-page/album-37.jpg' },
//   { src: '/assets/img/album-page/album-38.jpg' },
//   { src: '/assets/img/album-page/album-39.jpg' },
//   { src: '/assets/img/album-page/album-40.jpg' },
//   { src: '/assets/img/album-page/album-41.jpg' },
//   { src: '/assets/img/album-page/album-42.jpg' },
//   { src: '/assets/img/album-page/album-43.jpg' },
//   { src: '/assets/img/album-page/album-44.jpg' },
//   { src: '/assets/img/album-page/album-45.jpg' },
//   { src: '/assets/img/album-page/album-46.jpg' },
// ];

const MainContainer = (props) => {
  return (
    <div>
      <Container className='my-20'>
        <div className='text-[22px] leading-9 text-[#c5c5c5]'>
          <div className='text-white font-bold below-bottom'>
            <h3 className='!mb-0 mt-28 aboveMedium:mt-0'>
              DESIGNS AND MEANINGS
            </h3>
          </div>
        </div>
        <div className=' mt-12 text-[20px] text-[#aaaaaa]'>
          <b className='text-white'>Tattoo Designs and Meanings Album</b>
          <br />
          In the photo album you see on this website, there are examples of
          standard Sak Yant tattoos designed according to traditional structures
          and principles. Each pattern has its own specific meaning, and we have
          provided basic meanings for you to understand.
          <br />
          <br />
          However, if you wish to explore a deeper meaning or a custom design
          tailored to your personality and goals, you can provide additional
          details during the booking process. This will ensure that the Sak Yant
          you receive is truly suitable for you.
          <br />
          <br />
          <b className='text-white'> Size and Price</b>
          <ul className='p-6 !list-disc'>
            <li>
              The tattoos in this album have clearly listed prices, which are
              for the standard size, around 15-18 centimeters.
            </li>
            <li>
              If you prefer a larger size, we will notify you before starting
              the work. However, generally, when you arrive and have a
              discussion, you will receive a size that is appropriate for your
              body and often at the same price.
            </li>
            <li>
              Consultation is essential to ensure that the size and placement of
              the tattoo are most suitable for your body.
            </li>
          </ul>
          <b className='text-white'>Meaning of the Tattoos</b>
          <br />
          While the basic meanings of each design are listed in the album, some
          patterns may have deeper details related to beliefs and personal
          energy. If you wish to understand the meaning more deeply, including
          the power and appropriateness of the Sak Yant for you, you should
          contact the master directly.
          <br />
          <br />
          Here, we value the meaning of the tattoo as much as the art on your
          body. A Sak Yant tattoo is not just a design; it is a symbol of power,
          faith, and personal protection.
          <br />
          <br />
          <b className='text-white'>Summary</b>
          <ul className='p-6 !list-disc'>
            <li>
              The tattoos in the album are standard designs with basic meanings.
            </li>
            <li>
              If you want a deeper meaning or a custom design, you can provide
              details during the booking process.
            </li>
            <li>
              The standard size is 15-18 cm, and the price generally remains the
              same.
            </li>
            <li>
              Pre-tattoo consultation is important to ensure the best placement
              and size for you.
            </li>
            <li>
              For a deeper understanding of the meaning, it is recommended to
              consult with the master directly.
            </li>
          </ul>
          <br />
          We hope that you will have the best experience from your Sak Yant
          tattoo, and we are happy to help you find the design that best suits
          your identity. Explore our diverse collection of designs, categorized
          for your convenience. Browse through the albums below to find the
          perfect design that resonates with you.
        </div>
        <div className='mt-12'>
          <h5 className='text-white font-semibold aboveMedium:mb-9'>
            Popular Designs
          </h5>
          <SwipperSliderContain
            swiperData={popularDesignArray}
            imageType={'contain'}
            largeHeight={'600px'}
            mobileHeight={'280px'}
            dynamic={true}
          />
        </div>
        <div className='mt-6 aboveMedium:mt-12'>
          <h5 className='text-white font-semibold aboveMedium:mb-9'>
            Lotus and Sarika
          </h5>
          <SwipperSliderContain
            swiperData={lotusArray}
            imageType={'contain'}
            largeHeight={'600px'}
            mobileHeight={'280px'}
            dynamic={true}
          />
        </div>
        <div className='mt-6 aboveMedium:mt-12'>
          <h5 className='text-white font-semibold aboveMedium:mb-9'>
            Tiger Yant
          </h5>
          <SwipperSliderContain
            swiperData={tigerYantArray}
            imageType={'contain'}
            largeHeight={'600px'}
            mobileHeight={'280px'}
            dynamic={true}
          />
        </div>
        <div className='mt-6 aboveMedium:mt-12'>
          <h5 className='text-white font-semibold aboveMedium:mb-9'>
            Yant Ganesha
          </h5>
          <SwipperSliderContain
            swiperData={yantGaneshaArray}
            imageType={'contain'}
            mobileHeight={'280px'}
            largeHeight={'600px'}
            dynamic={true}
          />
        </div>
        <div className='mt-6 aboveMedium:mt-12'>
          <h5 className='text-white font-semibold aboveMedium:mb-9'>
            Por Kea (Hermit)
          </h5>
          <SwipperSliderContain
            swiperData={porKeaArray}
            imageType={'contain'}
            largeHeight={'600px'}
            mobileHeight={'280px'}
            dynamic={true}
          />
        </div>
        <div className='mt-6 aboveMedium:mt-12'>
          <h5 className='text-white font-semibold aboveMedium:mb-9'>
            Yant of the Deity
          </h5>
          <SwipperSliderContain
            swiperData={yantOfTheDeityArray}
            imageType={'contain'}
            largeHeight={'600px'}
            mobileHeight={'280px'}
            dynamic={true}
          />
        </div>
        <div className='mt-6 aboveMedium:mt-12'>
          <h5 className='text-white font-semibold aboveMedium:mb-9'>Hanuman</h5>
          <SwipperSliderContain
            swiperData={hanumanArray}
            imageType={'contain'}
            largeHeight={'600px'}
            mobileHeight={'280px'}
            dynamic={true}
          />
        </div>
        <div className='mt-6 aboveMedium:mt-12'>
          <h5 className='text-white font-semibold aboveMedium:mb-9'>
            Naga Yant (Prayanak)
          </h5>
          <SwipperSliderContain
            swiperData={nagaYantArray}
            imageType={'contain'}
            largeHeight={'600px'}
            mobileHeight={'280px'}
            dynamic={true}
          />
        </div>

        {/* <div className='flex flex-col items-center gap-12 mt-12'>
          {albumImageData.map((image, index) => (
            <Image
              key={index}
              src={image.src}
              alt='album'
              className='object-cover flex'
            />
          ))}
        </div> */}
      </Container>
    </div>
  );
};

export default MainContainer;
