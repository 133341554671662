const artSlider = [
  { img: '/assets/img/art-page/art-slider/art-slider-1.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-2.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-3.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-4.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-5.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-6.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-7.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-8.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-9.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-10.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-11.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-12.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-13.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-14.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-15.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-16.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-17.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-18.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-19.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-20.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-21.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-22.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-23.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-24.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-25.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-26.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-27.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-28.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-29.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-30.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-31.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-32.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-33.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-34.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-35.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-36.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-37.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-38.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-39.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-40.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-41.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-42.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-43.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-44.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-45.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-46.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-47.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-48.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-49.jpg' },
  { img: '/assets/img/art-page/art-slider/art-slider-50.jpg' },
];

module.exports = artSlider;
