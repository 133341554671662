const HeaderData = [
  {
    title: 'HOME',
    link: '/',
  },
  {
    title: 'HISTORY',
    link: '/history',
  },
  {
    title: 'SAKYANT SERVICE',
    dropdown: [
      {
        title: 'The Meaning of Tattoos and Custom Designs',
        link: '/services/custom-designs',
      },
      {
        title: 'Popular Sak Yant Designs & Their Meanings',
        link: '/services/populars',
      },
      {
        title: 'Booking & Appointments',
        link: '/services/booking',
      },
      {
        title:
          'Preparation and Sacred Rituals Before Receiving a Sak Yant Tattoo',
        link: '/services/preparations',
      },
      {
        title: 'Hygiene and Safety Standards – Our Needles and Ink',
        link: '/services/saftey-standards',
      },
      {
        title: 'Our Work Schedule and Team',
        link: '/services/team',
      },
    ],
  },
  {
    title: 'Design AND MEANING',
    link: '/design-and-meaning',
  },
  {
    title: 'ARTWORK',
    link: '/artwork',
  },
  {
    title: 'CONTACT US',
    link: '/contact-us',
  },
  {
    title: 'GALLERY',
    link: '/gallery',
  },
];

export default HeaderData;
