import { m } from 'framer-motion';
import React, { useEffect, useRef, memo } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Keyboard } from 'swiper/modules';
import { Col, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const swiperData = [
  {
    img: '/assets/img/home-page/main-00.jpg',
  },
  {
    img: '/assets/img/home-page/main-1.jpeg',
  },
  {
    img: '/assets/img/home-page/main-6.jpeg',
  },
  {
    img: '/assets/img/home-page/main-9.jpg',
  },
];
function SwipperSection() {
  return (
    <section className='p-0 pt-6 overflow-hidden 100vh'>
      <Swiper
        style={{ cursor: `url(/assets/img/webp/icon-move-light.webp)` }}
        modules={[Pagination, Keyboard, Autoplay]}
        slidesPerView={1}
        keyboard={{ enabled: true, onlyInViewport: true }}
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        direction='horizontal'
        breakpoints={{ 991: { direction: 'vertical' } }}
        className='xs:h-[330px] full-screen swiper-pagination-01 swiper-pagination-light swiper-pagination-medium vertical-white-move'
      >
        {swiperData.map((item, i) => {
          return (
            <SwiperSlide
              key={i}
              className='bg-no-repeat bg-cover relative overflow-hidden bg-center'
              style={{ backgroundImage: `url(${item.img})` }}
            >
              <div className='absolute top-0 left-0 w-full h-full bg-transparent-gradient-smoky-black bg-right opacity-1'></div>
              <Container className='h-full'>
                <Row className='flex flex-col justify-center h-full'>
                  <Col
                    xs={10}
                    xl={7}
                    md={7}
                    sm={6}
                    lg={8}
                    className='relative flex flex-column justify-center text-start py-[7rem] h-[100vh] md:h-[60vh]'
                  >
                    <h1 className='font-serif font-light text-[130px] text-white leading-[120px] tracking-[-6px] lg:text-[110px] lg:leading-[100px] md:text-[90px] md:leading-[85px] sm:text-[50px] sm:leading-[50px] sm:-tracking-[.5px] xs:text-[60px] xs:leading-[65px]'>
                      <span className='font-semibold block'>
                        {item.firsttitle}
                      </span>
                      {item.secondtitle}
                    </h1>
                    {/* <Link
                      aria-label="Watch projects"
                      to={item.link ? item.link : "#"}
                      className="left-[15px] bottom-[100px] z-[100] absolute md:bottom-[50px] sm:bottom-0"
                    >
                      <span className="flex items-center text-center hover:text-[#828282]">
                        <span className="text-white font-medium text-xmd font-serif uppercase inline-block align-middle">
                          Watch projects
                        </span>
                      </span>
                    </Link> */}
                  </Col>
                </Row>
              </Container>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
}

export default memo(SwipperSection);
