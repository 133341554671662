import { Suspense, useEffect, useState } from 'react';

// Libraries
import { Routes, Route, useLocation } from 'react-router-dom';
import retina from 'retinajs';
import { AnimatePresence } from 'framer-motion';
import { HelmetProvider } from 'react-helmet-async';

// Context
import GlobalContext from './Litho/Context/Context';

// Components
import ScrollToTopButton from './Litho/Components/ScrollToTop';

import 'react-toastify/dist/ReactToastify.css';

// Home
import Litho from './Litho';
import HomePage from './Pages/HomePage';
import HistoryPage from './Pages/HistoryPage';
import ArtWorkPage from './Pages/ArtPage';
import AlbumPage from './Pages/AlbumPage';
import BookingPage from './Pages/Services/BookingPage';
import ContactPage from './Pages/ContactPage';
import GalleryPage from './Pages/GalleryPage';
import CustomDesignsPage from './Pages/Services/CustomDesigns';
import PopularPage from './Pages/Services/PopularPage';
import PreparationPage from './Pages/Services/PreparationsPage';
import HygienePage from './Pages/Services/HygienePage';
import TeamPage from './Pages/Services/TeamPage';

import PageNotFoundPage from './Pages/PageNotFoundPage';
import { ToastContainer } from 'react-toastify';

function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false,
  });
  const location = useLocation();

  // RetinaJS
  useEffect(() => {
    window.addEventListener('load', retina(document.querySelectorAll('img')));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      import('./Litho/Functions/Utilities').then((module) => {
        module.SetHeaderMenuPos();
        module.setDocumentFullHeight();
      });
    }, 1000);
  }, [location]);

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector('body').classList.add('overflow-hidden');
    } else {
      document.querySelector('body').classList.remove('overflow-hidden');
    }
  }, [isModalOpen]);

  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <HelmetProvider>
      <GlobalContext.Provider
        value={{
          headerHeight,
          setHeaderHeight,
          footerHeight,
          setFooterHeight,
          isModalOpen,
          setIsModalOpen,
          customModal,
          setCustomModal,
        }}
      >
        <div
          className='App bg-tsecondary'
          style={{ '--header-height': `${headerHeight}px` }}
        >
          {
            <main
              style={{ marginTop: headerHeight, marginBottom: footerHeight }}
            >
              <ScrollToTopButton />
              <AnimatePresence mode='wait'>
                <Suspense fallback={<></>}>
                  <Routes>
                    <Route path='litho/*' element={<Litho />} />
                    <Route path='/' element={<HomePage />} />
                    <Route path='/history' element={<HistoryPage />} />
                    <Route path='/artwork' element={<ArtWorkPage />} />
                    <Route path='/design-and-meaning' element={<AlbumPage />} />

                    <Route path='/services/booking' element={<BookingPage />} />
                    <Route path='/contact-us' element={<ContactPage />} />
                    <Route
                      path='/services/populars'
                      element={<PopularPage />}
                    />
                    <Route
                      path='/services/saftey-standards'
                      element={<HygienePage />}
                    />
                    <Route path='/services/team' element={<TeamPage />} />

                    <Route
                      path='/services/preparations'
                      element={<PreparationPage />}
                    />
                    <Route
                      path='/services/custom-designs'
                      element={<CustomDesignsPage />}
                    />

                    <Route path='/gallery' element={<GalleryPage />} />

                    <Route path='/*' element={<PageNotFoundPage />} />
                  </Routes>
                  <ToastContainer
                    position='top-center'
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={false}
                    pauseOnHover={false}
                    theme='dark'
                  />
                </Suspense>
              </AnimatePresence>
            </main>
          }
        </div>
      </GlobalContext.Provider>
    </HelmetProvider>
  );
}

export default App;
