import PageWrapper from '../../components/basic/PageWrapper';
import MainContainer from '../../components/components/Services/Hygiene/MainContainer';

const HygienePage = () => {
  return (
    <PageWrapper>
      <MainContainer />
    </PageWrapper>
  );
};

export default HygienePage;
