import React, { useState } from 'react';

// Libraries
import { Container, Image } from 'react-bootstrap';
import SwipperSlider from '../../../basic/SwipperSlider';
import SwipperSliderContain from '../../../basic/SwipperSliderContain';
const swiperData = [
  {
    img: '/assets/img/services/populars-designs/menu-2.2-slide-1.jpg',
  },
  {
    img: '/assets/img/services/populars-designs/menu-2.2-slide-2.jpg',
  },
  {
    img: '/assets/img/services/populars-designs/menu-2.2-slide-3.jpg',
  },
  {
    img: '/assets/img/services/populars-designs/menu-2.2-slide-4.jpg',
  },
  {
    img: '/assets/img/services/populars-designs/menu-2.2-slide-5.jpg',
  },
  {
    img: '/assets/img/services/populars-designs/menu-2.2-slide-6.jpg',
  },
  {
    img: '/assets/img/services/populars-designs/menu-2.2-slide-7.jpg',
  },
  {
    img: '/assets/img/services/populars-designs/menu-2.2-slide-8.jpg',
  },
  {
    img: '/assets/img/services/populars-designs/menu-2.2-slide-9.jpg',
  },
  {
    img: '/assets/img/services/populars-designs/menu-2.2-slide-10.jpg',
  },
  {
    img: '/assets/img/services/populars-designs/menu-2.2-slide-11.jpg',
  },
  {
    img: '/assets/img/services/populars-designs/menu-2.2-slide-12.jpg',
  },
  {
    img: '/assets/img/services/populars-designs/menu-2.2-slide-13.jpg',
  },
  {
    img: '/assets/img/services/populars-designs/menu-2.2-slide-14.jpg',
  },
  {
    img: '/assets/img/services/populars-designs/menu-2.2-slide-15.jpg',
  },
  {
    img: '/assets/img/services/populars-designs/menu-2.2-slide-16.jpg',
  },
  {
    img: '/assets/img/services/populars-designs/menu-2.2-slide-17.jpg',
  },
  {
    img: '/assets/img/services/populars-designs/menu-2.2-slide-18.jpg',
  },
];

const MainContainer = (props) => {
  return (
    <div>
      <Container className='mt-20'>
        <div className='text-[22px] leading-9 text-[#c5c5c5]'>
          <div className='text-white font-bold below-bottom'>
            <h5 className='!mb-0 mt-28 aboveMedium:mt-0'>
              Popular Sak Yant Designs & Their Meanings
            </h5>
          </div>
        </div>

        <div className='flex flex-col mt-12 text-[20px] text-[#c5c5c5]'>
          At Sak Yant Bangkok, each tattoo carries deep personal significance,
          reflecting the spiritual essence of the individual receiving it.
          Beyond these personalized designs, we also offer traditional and
          ancient Sak Yant patterns, which have stood the test of time and are
          now widely recognized for their powerful meanings and sacred
          symbolism.
          <br />
          <br />
          In this section, we showcase some of the most popular Sak Yant
          designs, which are commonly chosen by people from all walks of life.
          These designs are known for their broad spiritual benefits, offering
          protection, prosperity, and good fortune. Many of these patterns have
          been worn by celebrities, monks, warriors, and spiritual seekers for
          centuries.
          <br />
          <br />
          If you are drawn to a specific design for its beauty and significance,
          you are welcome to consider it as an option for your own Sak Yant.
          However, we strongly recommend consulting with our Master before
          making a final decision. The true essence of Sak Yant lies not just in
          its appearance but in its ability to reflect your personal energy,
          life path, and spiritual needs.
          <br />
          <br />
          Here, you will find examples of some of the most beloved and
          time-honored designs. Each one has been carefully selected for its
          unique symbolism and profound connection to Thai spiritual traditions
        </div>
        <div className='mt-12'>
          <SwipperSliderContain
            swiperData={swiperData}
            imageType={'contain'}
            mobileHeight={'350px'}
            largeHeight={'600px'}
            dynamic={true}
          />
        </div>
      </Container>
    </div>
  );
};

export default MainContainer;
