import React, { memo } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Keyboard, Navigation } from 'swiper/modules';
import { Col, Row, Container } from 'react-bootstrap';

function SwipperSlider({
  swiperData,
  imageType,
  mobileHeight,
  largeHeight,
  dynamic = false,
}) {
  return (
    <section className='p-0 pt-6 overflow-hidden 100vh'>
      <Swiper
        style={{ cursor: `url(/assets/img/webp/icon-move-light.webp)` }}
        modules={[Pagination, Keyboard, Autoplay, Navigation]}
        slidesPerView={1}
        navigation={true}
        autoplay={{ delay: 4000, disableOnInteraction: true }}
        keyboard={{ enabled: true, onlyInViewport: true }}
        pagination={{ clickable: true, dynamicBullets: dynamic }}
        direction='horizontal'
        className={`xs:h-[${mobileHeight}] h-[${largeHeight}] full-screen swiper-pagination-01 swiper-pagination-light swiper-pagination-medium vertical-white-move`}
      >
        {swiperData.map((item, i) => {
          return (
            <SwiperSlide
              key={i}
              className={`bg-no-repeat bg-center relative overflow-hidden bg-cover`}
              style={{ backgroundImage: `url(${item.img})` }}
            >
              <div className='absolute top-0 left-0 w-full h-full bg-right opacity-1'></div>
              <Container className='h-full'>
                <Row className='flex flex-col justify-center h-full'>
                  <Col
                    xs={10}
                    xl={7}
                    md={7}
                    sm={6}
                    lg={8}
                    className='relative flex flex-column justify-center text-start py-[7rem] h-[100vh] md:h-[60vh]'
                  >
                    <h1 className='font-serif font-light text-[130px] text-white leading-[120px] tracking-[-6px] lg:text-[110px] lg:leading-[100px] md:text-[90px] md:leading-[85px] sm:text-[50px] sm:leading-[50px] sm:-tracking-[.5px] xs:text-[60px] xs:leading-[65px]'>
                      <span className='font-semibold block'>
                        {item.firsttitle}
                      </span>
                      {item.secondtitle}
                    </h1>
                  </Col>
                </Row>
              </Container>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
}

export default memo(SwipperSlider);
